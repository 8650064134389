import React, { FC } from "react";
import { CircularProgress, CircularProgressProps } from "@mui/material";

const LoadingIndicator: FC<CircularProgressProps> = (props) => {
  return (
    <CircularProgress
      variant="indeterminate"
      color="inherit"
      thickness={6}
      {...props}
    />
  );
};

export default LoadingIndicator;
