import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { FC, useState } from "react";

type Props = {
  severity: AlertColor;
  message: string;
  hideAfter?: number;
  onClose?: () => void;
};

const StatusMessage: FC<Props> = ({
  severity,
  message,
  hideAfter = 5000,
  onClose,
}) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={hideAfter}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{ "&&": { mb: 10 } }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{
          width: "100%",
          minHeight: 80,
          alignItems: "center",
          border: (theme) => `2px solid ${theme.palette[severity].main}`,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default StatusMessage;
