import React, { FC, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import WaitingRoomHelpSeeker from "./WaitingRoomHelpSeeker";
import DefaultLayout from "~/screens/layouts/DefaultLayout";
import LoadingIndicator from "../LoadingIndicator";
import type { GroupChannel as GroupChannelType } from "@sendbird/chat/groupChannel";

type Props = {
  isStaffMember: boolean;
  isStarted: boolean;
  channel?: GroupChannelType;
  onStart: () => void;
  onCheckin: (value?: string) => void;
};

const WaitingRoom: FC<Props> = ({
  isStaffMember,
  isStarted,
  channel = null as unknown as GroupChannelType,
  onStart,
  onCheckin,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasStarted, setHasStarted] = useState(isStarted);
  const [checkin, setCheckin] = useState("");

  useEffect(() => {
    if (channel && !isStaffMember && !hasStarted) {
      const handle = setInterval(async () => {
        await channel.refresh();

        const started = !!channel.lastMessage;
        if (started) clearInterval(handle);
        setHasStarted(started);
      }, 10000);
      return () => clearInterval(handle);
    }
  }, [hasStarted, setHasStarted, channel, isStaffMember]);

  useEffect(() => {
    if (!isStaffMember || hasStarted) return;
    const handle = setInterval(async () => {
      await channel?.refresh();
      await channel?.getMetaData(["checkin"]).then((r: any) => {
        setCheckin(r.checkin);
        setLoading(false);
        if (r.checkin) clearInterval(handle);
      });
    }, 3000);
    return () => clearInterval(handle);
  }, [isStaffMember, hasStarted, channel, checkin]);

  if (!isStaffMember) {
    return (
      <WaitingRoomHelpSeeker
        hasStarted={hasStarted}
        onJoin={onStart}
        onCheckin={onCheckin}
      />
    );
  }
  return (
    <DefaultLayout
      title="Take a moment and get ready for your session"
      sx={{ height: "100%" }}
    >
      <Typography>
        If at anytime you are unsure about what to say in the chat or identify
        risk/duty of care concerns please contact tonight's PeerChat Supervisor
        who will be there to help you out.
      </Typography>
      <Typography>
        It is up to you what parts of your lived experience you choose to share
        with the young person and what questions you feel comfortable to answer.
      </Typography>
      <Typography>
        Please remember to lock the chat once you have finished your session.
      </Typography>

      <Typography
        sx={{ my: 4, p: 2, bgcolor: "background.paper", borderRadius: 2 }}
      >
        {loading ? (
          <LoadingIndicator />
        ) : checkin ? (
          `Help seeker responded to the checkin with: ${checkin}`
        ) : (
          "Help seeker has not checked in yet"
        )}
      </Typography>

      <Typography>
        Click "Start the chat" to let the help seeker know when you're ready.
      </Typography>
      <Button color="primary" onClick={onStart}>
        Start the chat
      </Button>
    </DefaultLayout>
  );
};

export default WaitingRoom;
