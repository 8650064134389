import React, { FC } from "react";
import { Portal } from "@mui/material";

type HeaderActionProps = {
  children: React.ReactNode;
};
const HeaderAction: FC<HeaderActionProps> = ({ children }) => {
  return (
    <Portal container={() => document.querySelector("#header-actions")}>
      {children}
    </Portal>
  );
};

export default HeaderAction;
