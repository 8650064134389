import { getSecureAxiosClient } from "~/utils/auth";

const client = getSecureAxiosClient(process.env.REACT_APP_API_BASE!);

export const listUsers = async () =>
  client.get<ContactableUser[]>("/users").then((res) => res.data);

export const getUser = async (userId: string) =>
  client
    .get<ContactableUser>(`/users/${userId}/contact`)
    .then((res) => res.data);

export const getProfile = async (userId: string) =>
  client.get(`/users/${userId}`).then((res) => res.data as UserProfile);

export const updateLastLoginIp = async () =>
  client.get(`/last-login`).catch(() => {});
