import dayjs, { Dayjs, UnitType } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export type DateLike = number | string | Date | Dayjs;
export const formatBookingDate = (date: DateLike): string =>
  dayjs(date).format("dddd MMMM D, YYYY [at] h:mma").replace(/:00/, "");

export const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone || "Australia/Sydney";

export const currentOffset = (timezone: string): string =>
  dayjs().tz(timezone).format("Z");

export const currentTimeInTz = (timezone: string): string =>
  dayjs().tz(timezone).format("h:mma");

export const getDuration = (
  start: DateLike,
  end: DateLike,
  units: UnitType = "minutes"
): number => dayjs(end).diff(dayjs(start), units);

type FormatDateOptions = {
  fallbackFormat?: string;
  todayFormat?: string;
  tomorrowFormat?: string;
};

export const formatFriendlyDate = (
  date: DateLike,
  options: FormatDateOptions = {
    todayFormat: "[today]",
    tomorrowFormat: "[tomorrow]",
    fallbackFormat: "[on] dddd D MMMM",
  }
): string => {
  const bookedDate = dayjs(date);
  const today = dayjs().startOf("day");
  const diff = bookedDate.diff(today, "day");

  switch (diff) {
    case 0:
      return bookedDate.format(options.todayFormat);
    case 1:
      return bookedDate.format(options.tomorrowFormat);
    default:
      return bookedDate.format(options.fallbackFormat);
  }
};

// from IANA timezone list for Australia
export const timezoneOptions = [
  { value: "Australia/Sydney", label: "Sydney" },
  { value: "Australia/Adelaide", label: "Adelaide" },
  { value: "Australia/Brisbane", label: "Brisbane" },
  { value: "Australia/Broken_Hill", label: "Broken Hill" },
  { value: "Australia/Darwin", label: "Darwin" },
  { value: "Australia/Eucla", label: "Eucla" },
  { value: "Australia/Hobart", label: "Hobart" },
  { value: "Australia/Lindeman", label: "Lindeman" },
  { value: "Australia/Lord_Howe", label: "Lord Howe Island" },
  { value: "Australia/Melbourne", label: "Melbourne" },
  { value: "Australia/Perth", label: "Perth" },
]
  .map((tz) => {
    const offset = currentOffset(tz.value);
    return {
      ...tz,
      offset,
      label: `${tz.label} (${offset})`,
    };
  })
  .sort((a, b) => {
    const offsetComp = a.offset.localeCompare(b.offset);
    return offsetComp || a.label.localeCompare(b.label);
  });
