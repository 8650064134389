import React, { FC, ReactNode } from "react";
import {
  Container,
  ContainerProps,
  Stack,
  StackProps,
  Box,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

export type DefaultLayoutProps = {
  title?: string;
  intro?: ReactNode;
  sx?: ContainerProps["sx"];
  stackSx?: StackProps["sx"];
  containerWidth?: ContainerProps["maxWidth"];
  noHeaderColour?: boolean;
  middleSection?: ReactNode;
  titleAlign?: TypographyProps["align"];
  children: ReactNode;
};

const DefaultLayout: FC<DefaultLayoutProps> = ({
  title,
  intro,
  sx,
  stackSx,
  children,
  containerWidth = "md",
  noHeaderColour = false,
  middleSection,
  titleAlign = "inherit",
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {(title || intro) && (
        <Box
          sx={{
            pt: 4,
            pb: [2, 4],
            mb: 2,
            bgcolor: noHeaderColour
              ? undefined
              : (theme) => theme.palette.headingBg.main,
          }}
        >
          <Box
            sx={{
              mx: [0, "auto"],
              px: [2, 0],
              maxWidth: (theme) => theme.breakpoints.values.md,
            }}
          >
            {title && (
              <Typography variant="h1" align={titleAlign}>
                {title}
              </Typography>
            )}
            {intro && typeof intro === "string" && (
              <Typography sx={{ my: 2 }}>{intro}</Typography>
            )}
            {intro && typeof intro !== "string" && intro}
          </Box>
        </Box>
      )}
      {middleSection}
      <Container
        maxWidth={containerWidth}
        sx={{ flex: 1, px: [2, 0], pb: 4, ...sx }}
      >
        <Stack spacing={2} sx={stackSx}>
          {children}
        </Stack>
      </Container>
    </>
  );
};

export default DefaultLayout;
