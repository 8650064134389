import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useSendbirdStateContext,
  sendBirdSelectors,
  sendbirdSelectors,
} from "@sendbird/uikit-react";
import type { GroupChannel as GroupChannelType } from "@sendbird/chat/groupChannel";
import GroupChannelHandler from "@sendbird/uikit-react/handlers/GroupChannelHandler";
import { AuthContext } from "~/components/providers/AuthProvider";
import PeerWorkerProfileProvider from "./providers/PeerWorkerProfileProvider";
import gaCreate from "~/utils/gaCreate";
import LeaveChatModal from "./chat/LeaveChatModal";
import LoadingBox from "./chat/LoadingBox";
import SurveyDialog from "./survey/SurveyDialog";
import {
  MessageCustomType,
  SessionEndType,
  SessionState,
  atLatestMessage,
  scrollToLatestMessage,
} from "~/utils/chat";
import ChatWindow from "./chat/ChatWindow";
import { globalChatStyles } from "./chat/styles";
import { messageObserver } from "../utils/chat";
import WaitingRoom from "./waitingroom/WaitingRoom";
import type { UserMessageCreateParams } from "@sendbird/chat/message";
import { v4 as uuidv4 } from "uuid";
//import { ContactConsentModal } from "./chat/ContactConsentModal";
import { useNavigate } from "react-router-dom";
import type { MetaData } from "@sendbird/chat";

type Props = {
  sessionId: string;
};

const groupChannelHandlerKey = uuidv4();

const ChatSession: FC<Props> = ({ sessionId }) => {
  const navigate = useNavigate();

  const channelUrl = sessionId.includes("peersupport")
    ? sessionId
    : `peersupport-${sessionId}`;
  const { isStaffMember, user } = useContext(AuthContext);

  const globalStore = useSendbirdStateContext();
  const sendUserMessage = sendbirdSelectors.getSendUserMessage(globalStore);

  const [peerWorkerId, setPeerWorkerId] = useState("");
  const [sessionState, setSessionState] = useState<SessionState>(
    SessionState.Loading
  );
  const [channel, setChannel] = useState<GroupChannelType>(
    null as unknown as GroupChannelType
  );
  const [isLockClicked, setIsLockedClicked] = useState<boolean>(false);
  const [leaveModal, setLeaveModal] = useState<boolean>(false);
  const [openSurvey, setOpenSurvey] = useState<boolean>(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState<boolean>(false);
  const [howChatEnded, setHowChatEnded] = useState<SessionEndType>();

  const [surveyCompleteOrExited, setSurveyCompleteOrExited] =
    useState<boolean>(false);

  const sb = sendBirdSelectors.getSdk(globalStore)!;

  const handleJoinSession = useCallback(async () => {
    gaCreate();

    window.ga?.("send", "event", "PeerChat", "Click", "Step 4 - Start session");

    setSessionState(SessionState.Running);
  }, []);

  const handleExitMetadata = useCallback(
    async (value: SessionEndType | "") => {
      const currentExitMetadata =
        ((await channel?.getMetaData(["howChatEnded"])) as {
          [key: string]: string;
        }) || {};

      switch (currentExitMetadata.howChatEnded) {
        case "helpseeker":
        case "peerworker-survey":
          // don't want to overwrite these values
          return;
        case "peerworker-locked": {
          if (value === "" || value === "peerworker-survey") {
            // channel has either been unlocked - remove value
            // or survey has been selected - can overwrite
            // don't want to overwrite if value is helpseeker
            await channel?.updateMetaData(
              {
                howChatEnded: value,
              },
              true
            );
            return;
          }
          return;
        }
        default: {
          await channel?.updateMetaData(
            {
              howChatEnded: value,
            },
            true
          );
          return;
        }
      }
    },
    [channel]
  );

  const ChannelHandler = useMemo(
    () =>
      new GroupChannelHandler({
        onChannelFrozen: async () => {
          setIsLockedClicked(true);
          setHowChatEnded("peerworker-locked");
          await handleExitMetadata("peerworker-locked");
        },
        onChannelUnfrozen: async () => {
          setIsLockedClicked(false);
          setHowChatEnded(undefined);
          await handleExitMetadata("");
        },
        onMetaDataUpdated: async (_, metadata) => {
          // Case when survey is activating by PW
          if (metadata.ended === "true" && !isStaffMember) {
            setSessionState(SessionState.Ended);
          }
        },
        onMessageReceived: (channel, message) => {
          if (
            message.customType === MessageCustomType.EndMsg ||
            message.customType === MessageCustomType.PeerWorkerLeft
          ) {
            setSessionState(SessionState.Ended);
          }
          const lastMessage = (channel as GroupChannelType).lastMessage;

          // if PW has already sent the start session message
          if (lastMessage?.customType === MessageCustomType.StartMsg) {
            setSessionState(SessionState.Started);
          }

          // Fixes issue on iOS mobile where latest messages are not being automatically scrolled to
          // https://reachout.atlassian.net/browse/RC-944
          if (atLatestMessage()) {
            scrollToLatestMessage();
          }
        },
      }),
    [handleExitMetadata, isStaffMember]
  );

  const handleSessionEnd = async () => {
    if (surveyCompleteOrExited) {
      return navigate("/");
    }
    setSessionState(SessionState.Ended);

    try {
      // if frozen and is HS do not send message (can't send message due to frozen
      //  and sendbird throws an error)
      if (channel && (!channel?.isFrozen || isStaffMember)) {
        sendUserMessage(
          channel,
          isStaffMember
            ? {
                message: "Peer worker has left the chat",
                customType: MessageCustomType.PeerWorkerLeft,
              }
            : {
                message: "End of the chat",
                customType: MessageCustomType.EndMsg,
              }
        );
      }

      await channel?.updateMetaData(
        {
          ended: "true",
        },
        true
      );
      await handleExitMetadata(howChatEnded!);
    } catch (ex) {
      console.error("Failed to end session!", ex);
    }
    setLeaveModal(false);
  };

  const handleCheckin = useCallback(
    async (value?: string) => {
      if (window.heap) {
        window.heap.track("checkin-before", {
          value: value || 0,
        });
      }
      if (value) await channel?.updateMetaData({ checkin: value }, true);
    },
    [channel]
  );

  // const handleConsentClose = useCallback(() => {
  //   setIsConsentModalOpen(false);
  //   setOpenSurvey(true);
  // }, []);

  const setChannelEnded = useCallback(async (channel: GroupChannelType) => {
    await channel.getMetaData([SessionState.Ended]).then((r: any) => {
      if (r[SessionState.Ended]) {
        setSessionState(SessionState.Ended);
      }
    });
  }, []);
  const updateChannelState = useCallback(
    (channel: GroupChannelType) => {
      if (channel.lastMessage?.customType === MessageCustomType.StartMsg) {
        setSessionState(SessionState.Started);
      } else if (channel.lastMessage) {
        setSessionState(SessionState.Running);
      } else {
        setSessionState(SessionState.Waiting);
      }
      setChannelEnded(channel);
    },
    [setChannelEnded]
  );

  const handleSurveyClose = useCallback(async () => {
    await handleSurveyComplete();
    setOpenSurvey(false);
    navigate("/");
  }, [setOpenSurvey, channel, surveyCompleteOrExited]);

  // persist completed state so survey doesn't re-appear
  const handleSurveyComplete = useCallback(async () => {
    const surveyCompleteData: MetaData = {
      surveyCompleteOrExited: "true",
    };
    try {
      const upsertIfKeyNotExist = true;
      await channel?.updateMetaData(surveyCompleteData, upsertIfKeyNotExist);
    } catch (err) {
      console.error(err);
    }
  }, [channel, surveyCompleteOrExited]);

  useEffect(() => {
    if (sb && sb.groupChannel) {
      sb.groupChannel.addGroupChannelHandler(
        groupChannelHandlerKey,
        ChannelHandler
      );

      try {
        (async () => {
          // To initially set channel and seniorPWId
          const channel = await sb.groupChannel.getChannel(channelUrl);

          if (!channel) {
            console.error(
              `Channel not found, user: ${user?.userId}, channelUrl: ${channelUrl}`
            );
            return;
          }
          setChannel(channel);
          const dataJson = channel.data ? JSON.parse(channel.data) : {};
          if (dataJson.peerWorkerId) setPeerWorkerId(dataJson.peerWorkerId);

          updateChannelState(channel);
        })();
      } catch (error) {
        console.error(
          `Failed to fetch Sendbird channel, userId: ${user?.userId}, channelUrl: ${channelUrl}, error: ${error}`
        );
      }

      return () => {
        sb.groupChannel.removeAllGroupChannelHandlers();
      };
    }
  }, [sb, ChannelHandler, channelUrl, updateChannelState]);

  const handleSessionStart = useCallback(async () => {
    if (channel) {
      sendUserMessage(
        channel,

        {
          message: "Session has been started",
          customType: MessageCustomType.StartMsg,
        }
      )
        .onFailed((ex) => {
          console.error("Failed to start session!", ex);
        })
        .onSucceeded(() => {
          updateChannelState(channel);
        });
    }
  }, [channel, sendUserMessage, updateChannelState]);

  useEffect(() => {
    if (
      sessionState === SessionState.Running ||
      sessionState === SessionState.Started
    ) {
      // Add missing aria-label to sendbird's send button for accessibility
      const messageInput = document.querySelector(".sendbird-message-input");
      if (messageInput) {
        messageObserver.observe(messageInput as HTMLInputElement, {
          childList: true,
        });
      }
    }

    return () => {
      messageObserver.disconnect();
    };
  }, [sessionState]);

  useEffect(() => {
    (async () => {
      const metadata = await channel?.getMetaData(["surveyCompleteOrExited"]);
      const complete = metadata && metadata?.surveyCompleteOrExited === "true";
      if (complete) {
        setSurveyCompleteOrExited(true);
      }
      if (!complete && sessionState === SessionState.Ended) {
        const timer = setTimeout(() => {
          //TODO: remove the following four lines when restoring the consent modal
          if (!isStaffMember) {
            setIsConsentModalOpen(false);
            setOpenSurvey(true);
          }
        }, 1500);
        return () => clearTimeout(timer);
      }
    })();
  }, [sessionState, channel]);

  const trackMessageSend = (messageParams: UserMessageCreateParams) => {
    const trimmed = messageParams.message.trim();

    if (window.heap) {
      window.heap.track("message-sent", { characterCount: trimmed.length });
    }

    const trimmedMessage = {
      ...messageParams,
      message: trimmed,
    };

    return trimmedMessage;
  };

  if (sessionState === SessionState.Loading) {
    return <LoadingBox />;
  }

  if (
    sessionState === SessionState.Waiting ||
    (sessionState === SessionState.Started && !isStaffMember)
  ) {
    return (
      <PeerWorkerProfileProvider userId={peerWorkerId}>
        <WaitingRoom
          channel={channel}
          isStaffMember={isStaffMember}
          isStarted={sessionState === SessionState.Started}
          onStart={() =>
            isStaffMember ? handleSessionStart() : handleJoinSession()
          }
          onCheckin={handleCheckin}
        />
      </PeerWorkerProfileProvider>
    );
  }

  return (
    <PeerWorkerProfileProvider userId={peerWorkerId}>
      {globalChatStyles}
      {/* All chat modals and dialogs */}
      <LeaveChatModal
        open={leaveModal}
        isStaffMember={isStaffMember}
        handleSessionEnd={handleSessionEnd}
        closeModal={() => {
          setLeaveModal(false);
        }}
      />
      {/* <EndedChatModal
        open={endedModal && !isStaffMember}
        openSurvey={() => setOpenSurvey(true)}
        closeModal={() => {
          setEndedModal(false);
        }}
      /> */}
      <SurveyDialog open={openSurvey} handleClose={handleSurveyClose} />
      {/* <ContactConsentModal
        open={isConsentModalOpen && !isStaffMember}
        onClose={handleConsentClose}
      /> */}
      {/* Main chat  */}
      <ChatWindow
        user={user}
        channel={channel}
        channelUrl={channelUrl}
        isStaffMember={isStaffMember}
        sessionState={sessionState}
        isLockClicked={isLockClicked}
        setLeaveModal={setLeaveModal}
        setHowChatEnded={setHowChatEnded}
        trackMessageSend={trackMessageSend}
        isConsentModalOpen={isConsentModalOpen}
        setIsConsentModalOpen={setIsConsentModalOpen}
        surveyCompleteOrExited={surveyCompleteOrExited}
      />
    </PeerWorkerProfileProvider>
  );
};

export default ChatSession;
