import React from "react";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  Typography,
  StepButton,
  ButtonBase,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { colours } from "~/theme";
import { visuallyHidden } from "@mui/utils";

export type SurveyStep = {
  title: string;
};
export type SurveySteps = SurveyStep[];

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 8px)",
    right: "calc(50% + 8px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colours.white.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colours.white.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colours.white.main,
    backgroundColor: colours.white.main,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StepIconRoot = styled(ButtonBase)<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  color: colours.white.main,
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: colours.peri.main,
  }),
  ...(ownerState.completed && {
    color: colours.peri.main,
  }),

  "& .StepIcon-circle": {
    position: "relative",
    zIndex: 99,
    width: 22,
    height: 22,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    ...(ownerState.active && {
      width: 42,
      height: 42,
    }),
    ...(ownerState.completed && {
      width: 42,
      height: 42,
    }),
  },
}));

function StepIcon({ active, completed, className }: StepIconProps) {
  return (
    <StepIconRoot
      ownerState={{ active, completed }}
      className={className}
      aria-current={active ? "true" : "false"}
    >
      <div className="StepIcon-circle" />
    </StepIconRoot>
  );
}

export default function Progress({
  steps,
  activeStep,
  onClickStep,
}: {
  steps: SurveySteps;
  activeStep?: number;
  onClickStep: (index: number) => void;
}) {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        role="navigation"
        alternativeLabel
        activeStep={activeStep}
        connector={<Connector />}
      >
        {steps.map(({ title }, i) => (
          <Step key={title} onClick={() => onClickStep(i)} className="survey">
            <StepButton
              href="#"
              LinkComponent={"a"}
              role="button"
              disabled={false}
              icon={<StepLabel StepIconComponent={StepIcon} />}
              tabIndex={i}
              disableRipple
              sx={{
                "& .Mui-focusVisible .StepIcon-circle": {
                  background: colours.peri.dark,
                },
              }}
            >
              <Typography sx={visuallyHidden}>
                {/* For screenreaders */}
                {title}
              </Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
