import React, { FC, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import FormField, { FormFieldProps } from "~/components/FormField";

const PasswordField: FC<FormFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = useMemo(() => {
    return (
      <IconButton
        onClick={() => setShowPassword(!showPassword)}
        title={`${showPassword ? "Hide" : "Show"} password`}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    );
  }, [showPassword]);

  return (
    <FormField
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: togglePassword,
      }}
      {...props}
    />
  );
};

export default PasswordField;
