import { CssBaseline } from "@mui/material";
import React, { FC } from "react";

import { BrowserRouter } from "react-router-dom";

import DarkModeManager from "~/components/providers/DarkModeManager";
import AuthProvider from "~/components/providers/AuthProvider";
import { Helmet, HelmetProvider } from "react-helmet-async";

type Props = {
  user: any;
  children: React.ReactNode;
};

// if you need to configure more providers they can be added here
const Providers: FC<Props> = ({ children, user }) => {
  return (
    <HelmetProvider>
      <AuthProvider startingUser={user}>
        <Helmet
          defaultTitle="ReachOut PeerChat"
          titleTemplate="%s | ReachOut PeerChat"
        />
        <DarkModeManager>
          <CssBaseline enableColorScheme />
          <BrowserRouter>{children}</BrowserRouter>
        </DarkModeManager>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default Providers;
