import React, { FC } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import dayjs from "dayjs";
import { formatFriendlyDate } from "~/utils/date";
import { colours } from "~/theme";
import { shallowObjectMatch } from "~/utils/booking";

type Props = {
  date: string;
  sessionTimes: SessionTime[];
  selectedSession?: SessionTime;
  onSelect: (time?: SessionTime) => void;
  startOpen?: boolean;
  removedAvailability?: SessionTime[];
};

const DayAvailability: FC<Props> = ({
  date,
  sessionTimes,
  selectedSession,
  onSelect,
  startOpen = false,
  removedAvailability = [],
}) => {
  return (
    <Accordion
      defaultExpanded={startOpen}
      elevation={0}
      sx={{ bgcolor: "background.default" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${date}-content`}
        id={`${date}-header`}
        sx={{
          "&.Mui-expanded .MuiTypography-root": {
            color: "primary.main",
            fontWeight: 700,
          },
        }}
      >
        <Typography>
          {formatFriendlyDate(date, {
            todayFormat: "dddd [| Today]",
            tomorrowFormat: "dddd [| Tomorrow]",
            fallbackFormat: "dddd",
          })}
        </Typography>
        <Typography ml="auto" mr={1}>
          {dayjs(date).format("DD MMM")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {sessionTimes.map((session) => {
            const isSelected = selectedSession === session;
            const isRemoved = !!removedAvailability.find((removedSession) =>
              shallowObjectMatch(session, removedSession)
            );
            return (
              <Grid item key={session.time}>
                <Button
                  disabled={isRemoved}
                  variant={isSelected ? "contained" : "outlined"}
                  sx={{
                    borderRadius: 1,
                    borderColor: isRemoved ? "#c9c9c9" : "primary.main",
                    fontWeight: "normal",
                    color: isRemoved
                      ? `${colours.white.main} !important`
                      : isSelected
                      ? undefined
                      : "text.primary",
                    bgcolor: isRemoved
                      ? "#c9c9c9 !important"
                      : isSelected
                      ? "primary.main"
                      : undefined,
                    fontSize: "1rem",

                    "&:hover": {
                      bgcolor: isSelected ? "primary.dark" : "rgba(0,0,0,0.1)",
                    },
                  }}
                  onClick={() => onSelect(isSelected ? undefined : session)}
                  aria-pressed={isSelected}
                >
                  {session.time}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DayAvailability;
