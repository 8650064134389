import { Theme, createTheme } from "@mui/material/styles";

import { cloneDeep, set } from "lodash";

export const pxToRem = (px: number): string =>
  `${(px / 16).toFixed(4).replace(/0+$/, "").replace(/\.$/, "")}rem`;

const defaultTheme = createTheme();

export const colours = {
  primary: {
    light: "#0B92E7",
    main: "#7049E6",
    dark: "#6542CF",
    contrastText: "#ffffff",
  },
  plum: {
    light: "#0B92E7",
    main: "#7049E6",
    dark: "#6542CF",
    contrastText: "#ffffff",
  },
  peri: {
    light: "#BFB8FF",
    main: "#AAA0FF",
    dark: "#8078BF",
    contrastText: "#001737",
  },
  primaryDark: {
    main: "#ADDBC9",
    dark: "#9CC5B5",
    contrastText: "#001737",
  },
  ice: {
    main: "#ADDBC9",
    dark: "#9CC5B5",
    contrastText: "#001737",
  },
  secondary: {
    light: "#9477EC",
    main: "#7049E6",
    dark: "#5437AC",
    contrastText: "#ffffff",
  },
  secondaryDark: {
    main: "#fdda2c",
  },
  urgent: {
    light: "#FF8F95",
    main: "#FF6971",
    dark: "#E55F66",
    contrastText: "#001737",
  },
  navy: {
    main: "#001737",
    dark: "#001129",
    contrastText: "#ffffff",
    tint: "#001737e6", // 90%
    lightTint: "#0017374d",
  },
  text: {
    light: "#ffffff",
    dark: "#001737",
  },
  illustrations: {
    yellow: "#FFF05F",
    pink: "#F9B4B8",
    blue: "#9FD3F7",
  },
  background: {
    default: "#001737", // navy
    light: "#ffffff",
    dark: "#00060E", // navy 900
  },
  white: {
    main: "#ffffff",
    dark: "#E5E5E5",
  },
  mallard: {
    light: "#E8F1F1",
  },
  grey: {
    100: "#CCCED4", // grey 100
    light: "#666D7F", // grey 300
    lighter: "#EBEBEE", //grey 50
    main: "#333C54", // grey 500
    80: "#667487", // grey used for PC iteration
  },
  french: {
    dark: "#305FB2", // french 700
    light: "#CFDFFB", // french 100
  },
  buff: {
    main: "#F7F1EB",
  },
  error: {
    light: "#B30C1A",
    dark: "#FF7A7A",
  },
  coreGrey: {
    lighter: "#98A1A9",
    light: "#666D7F",
    main: "#334554",
    dark: "#262D3F",
    contrastText: "#ffffff",
  },
};

const components: Theme["components"] = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 4,

        "& svg": {
          width: "2rem",
          height: "2rem",
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        fontWeight: 600,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: "none",
        borderRadius: 30,
      },
      contained: {
        "&.Mui-disabled": {
          background: colours.grey.lighter,
          color: colours.grey.light,
        },
      },
      containedPrimary: {
        "&.Mui-disabled": {
          background: colours.grey.lighter,
          color: colours.grey.light,
        },
      },
      containedSecondary: {
        "&.Mui-disabled": {
          background: defaultTheme.palette.grey[200],
          color: defaultTheme.palette.text.disabled,
        },
      },
    },
  },
};

const typography: Partial<Theme["typography"]> = {
  fontFamily: "'Work Sans', Arial, sans-serif",
  h1: { fontSize: 24, fontWeight: 700, lineHeight: 1.4 },
  h2: { fontSize: 24, fontWeight: 700, lineHeight: 1.4 },
  h3: { fontSize: 22, lineHeight: 1.4 },
  h4: { fontSize: 18, fontWeight: 700, lineHeight: 1.6 },
  h5: { fontSize: 18, lineHeight: 1.6 },
  h6: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.6,
  },
  body1: {
    fontSize: 16,
    whiteSpace: "pre-line",
    lineHeight: 1.6,
  },
  body2: {
    fontSize: 16,
    whiteSpace: "pre-line",
    lineHeight: 1.6,
  },
  caption: {
    fontSize: 14,
    lineHeight: 1.6,
  },
  button: {
    fontSize: 18,
    fontWeight: 500,
  },
};

const contrastThreshold = 4.5;

const darkThemeComponents = cloneDeep(components);
const overrides = {
  "MuiAlert.defaultProps.variant": "filled",
};
Object.entries(overrides).forEach(([k, v]) => set(darkThemeComponents, k, v));

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: colours.plum,
    secondary: colours.secondary,
    error: { main: colours.error.light },
    urgent: colours.urgent,
    navy: colours.navy,
    peri: colours.peri,
    ice: colours.ice,
    plum: colours.plum,
    coreGrey: colours.coreGrey,
    headingBg: {
      main: defaultTheme.palette.grey[100],
      contrastText: colours.text.dark,
    },
    text: { primary: colours.text.dark },
    background: {
      default: colours.background.light,
      paper: defaultTheme.palette.grey[100],
    },
    contrastThreshold,
  },
  components,
  typography,
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: colours.ice,
    secondary: colours.secondaryDark,
    error: { main: colours.error.dark },
    urgent: colours.urgent,
    navy: colours.navy,
    peri: colours.peri,
    ice: colours.ice,
    plum: colours.plum,
    coreGrey: colours.coreGrey,

    headingBg: {
      main: colours.grey.main,
      contrastText: colours.text.light,
    },
    text: { primary: colours.text.light },
    background: { default: colours.background.dark, paper: colours.grey.main },
    contrastThreshold,
  },
  components: darkThemeComponents,
  typography,
});
