import { Stack, Button, Link, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { userIsOverseas } from "~/utils/geolocation";
import BrandedBox from "./containers/BrandedBox";

type Props = {
  onDetectOverseas: () => void;
};

const OverseasUserIntercept: FC<Props> = ({ onDetectOverseas }) => {
  const [loading, setLoading] = useState(true);
  const [isOverseas, setIsOverseas] = useState(false);

  useEffect(() => {
    userIsOverseas()
      .then((res) => {
        setIsOverseas(res);
        if (res) onDetectOverseas();
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [onDetectOverseas]);

  if (loading || !isOverseas) return null;

  return (
    <BrandedBox
      themeType="navy"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: (theme) => theme.zIndex.appBar - 1,
        p: 4,
        textAlign: "center",
      }}
    >
      <Stack spacing={4}>
        <Typography variant="h2" component="p">
          It looks like you&rsquo;re visiting PeerChat from a country other than
          Australia.
        </Typography>
        <Typography>
          We are an Australian service and think you&rsquo;d benefit more from
          looking up a{" "}
          <Link
            href="https://www.facebook.com/help/103883219702654?helpref=page_content"
            color="inherit"
          >
            service in your country
          </Link>
          .
        </Typography>
        <Typography>
          You are also welcome to check out all the articles on our website.
        </Typography>
        <Button
          color="primary"
          href="https://au.reachout.com"
          sx={{ alignSelf: ["stretch", "center"] }}
        >
          Explore our articles
        </Button>
      </Stack>
    </BrandedBox>
  );
};

export default OverseasUserIntercept;
