import React, { FC, useContext } from "react";
import { AuthContext } from "~/components/providers/AuthProvider";
import ThinkingTimeMessage from "~/components/chat/ThinkingTimeMessage";
import StartMessage from "./StartMessage";
import ActionMessage from "./ActionMessage";
import DefaultMessage from "@sendbird/uikit-react/GroupChannel/components/Message";
import { GroupChannelUserMessage } from "../../utils/chat";
import { DateSeparator } from "./DateSeparator";

type Props = {
  message: GroupChannelUserMessage;
  sessionJoinedDate: string;
};

const Message: FC<Props> = ({ message, sessionJoinedDate }) => {
  const { isStaffMember, user } = useContext(AuthContext);

  switch (message.customType) {
    case "start_message":
      return (
        <>
          <DateSeparator date={sessionJoinedDate} />
          {isStaffMember ? (
            <ActionMessage>You started the chat</ActionMessage>
          ) : (
            <StartMessage />
          )}
        </>
      );

    case "thinking_time":
      return <ThinkingTimeMessage message={message} user={user!} />;
    case "peerworker_left_message":
      return <ActionMessage>Peer worker has left the chat</ActionMessage>;
    case "end_message":
      return <ActionMessage>End of the chat</ActionMessage>;
    default:
      return <DefaultMessage message={message}></DefaultMessage>;
  }
};

export default Message;
