import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { colours } from "~/theme";

type Props = {
  ratings: number[];
  heapClassName?: string;
  ratingValue: number | null;
  handleRatingValue: (
    event: React.MouseEvent<HTMLElement>,
    newRating: number | null
  ) => void;
};

export const Voting: FC<Props> = ({
  ratings,
  heapClassName,
  ratingValue,
  handleRatingValue,
}) => {
  return (
    <Stack spacing={1}>
      <Typography
        sx={{
          fontSize: "14px",
        }}
        textAlign="left"
      >
        Very unlikely
      </Typography>
      <ToggleButtonGroup
        value={ratingValue}
        exclusive
        onChange={handleRatingValue}
        sx={{
          flexWrap: "wrap",
          justifyContent: "center",
          "&& .MuiToggleButtonGroup-grouped": {
            color: colours.white.main,
            backgroundColor: colours.grey[80],
            fontSize: "18px",
            fontWeight: 700,
            width: "44px",
            height: "44px",
            m: 1,
            border: 0,
            borderRadius: "50%",
            "&:hover": { backgroundColor: colours.grey.light },
            "&.Mui-selected, &.Mui-selected:hover": {
              color: colours.navy.main,
              backgroundColor: colours.peri.main,
              "&:hover": { backgroundColor: colours.peri.light },
            },
          },
        }}
      >
        {ratings.map((value, index) => {
          return (
            <ToggleButton key={index} value={value} className={heapClassName}>
              {value}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <Typography
        sx={{
          fontSize: "14px",
        }}
        textAlign="right"
      >
        Very likely
      </Typography>
    </Stack>
  );
};
