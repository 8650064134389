import React, { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import { Link as RouterLink, LinkProps } from "react-router-dom";

const NavButton: FC<Omit<ButtonProps, "ref"> & LinkProps> = ({
  children,
  ...props
}) => {
  return (
    <Button component={RouterLink} {...props}>
      {children}
    </Button>
  );
};

export default NavButton;
