import React, { createContext, FC, useEffect, useMemo, useState } from "react";
import { CognitoUser } from "@aws-amplify/auth";
import { Hub, HubCallback } from "@aws-amplify/core";
import { AuthState } from "~/components/auth/utils";
import { updateLastLoginIp } from "~/services/users";

export type AuthContextState = {
  isLoggedIn: boolean;
  isPeerWorker: boolean;
  isSeniorPeerWorker: boolean;
  isAdmin: boolean;
  isStaffMember: boolean;
  user: User | null;
  authState: AuthState;
  authUser?: CognitoUser | any;
  setAuthUser: (user: CognitoUser | any) => void;
};

export type User = {
  userId: string;
  nickname?: string;
  accessToken?: string;
};

export const AuthContext = createContext<AuthContextState>({
  isLoggedIn: false,
  isPeerWorker: false,
  isSeniorPeerWorker: false,
  isAdmin: false,
  isStaffMember: false,
  user: {
    userId: "",
    nickname: undefined,
    accessToken: undefined,
  },
  authState: "loggedOut",
  authUser: undefined,
  setAuthUser: () => {},
});

type AuthProviderProps = {
  startingUser: any;
  children: React.ReactNode;
};
const AuthProvider: FC<AuthProviderProps> = ({ children, startingUser }) => {
  const [authUser, setAuthUser] = useState<CognitoUser | any>(startingUser);
  const [authState, setAuthState] = useState<AuthState>(
    startingUser ? "loggedIn" : "loggedOut"
  );

  useEffect(() => {
    // if already signed in, trigger update last login
    if (startingUser) {
      if (window.heap) window.heap.identify(startingUser.username);
      updateLastLoginIp();
    }

    const authListener: HubCallback = (data) => {
      switch (data.payload.event) {
        case "signIn":
          setAuthUser(data.payload.data);
          setAuthState("loggedIn");
          // after login, make a call to the api to capture the user's last accessed ip for Duty of Care
          updateLastLoginIp();
          // Cognito uses 'username' as the id value, so the below identifies the user
          // by their Cognito user id
          if (window.heap) {
            window.heap.identify(data.payload.data.username);

            window.heap.addUserProperties({
              isStaffMember: [
                "admin",
                "peerworker",
                "seniorpeerworker",
              ].includes(data.payload.data.attributes?.["custom:role"]),
            });
          }

          break;
        case "signOut":
          setAuthUser(null);
          setAuthState("loggedOut");
          if (window.heap) window.heap.resetIdentity();

          break;
        default:
        // console.log(data.payload);
      }
    };

    Hub.listen("auth", authListener);
    return () => Hub.remove("auth", authListener);
  }, []);  

  // convert cognito user attributes into sendbird user
  const user = useMemo(() => {
    if (!authUser) return null;
    return {
      userId: authUser.username,
      nickname: authUser.attributes?.["preferred_username"],
      accessToken: authUser.attributes?.["custom:sendbirdToken"],
      isAdmin: authUser.attributes?.["custom:role"] === "admin",
      isPeerWorker: authUser.attributes?.["custom:role"] === "peerworker",
      isSeniorPeerWorker:
        authUser.attributes?.["custom:role"] === "seniorpeerworker",
    };
  }, [authUser]);

  const contextValue = useMemo(
    () => ({
      user,
      authUser,
      authState,
      setAuthUser,

      // add convenience flags that are never undefined
      isLoggedIn: Boolean(user?.userId),
      isAdmin: Boolean(user?.isAdmin),
      isPeerWorker: Boolean(user?.isPeerWorker),
      isSeniorPeerWorker: Boolean(user?.isSeniorPeerWorker),
      isStaffMember: Boolean(
        user?.isAdmin || user?.isPeerWorker || user?.isSeniorPeerWorker
      ),
    }),
    [user, authUser, authState, setAuthUser]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
