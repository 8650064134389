import { Avatar, Box, Typography, TypographyProps } from "@mui/material";
import React, { FC } from "react";
import { colours } from "~/theme";
import BrandedBox from "./containers/BrandedBox";

type Props = UserProfile & {
  nameColour?: TypographyProps["color"];
};

const ProfileCard: FC<Props> = ({
  displayName,
  profile,
  avatarUrl,
  pronouns,
}) => {
  return (
    <BrandedBox
      themeType="mallard"
      sx={{
        mt: 1,
        display: "flex",
        alignItems: "stretch",
        justifyContent: "flex-start",
        borderRadius: "10px",
        px: 2,
        py: 1,
      }}
    >
      <Avatar src={avatarUrl} sx={{ width: 64, height: 64 }} />
      <Box
        sx={{
          ml: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="h2" component="p">
            {displayName}
          </Typography>
          {pronouns && (
            <Typography
              variant="h6"
              color={colours.grey.light}
              ml={1}
              component="p"
            >
              ({pronouns})
            </Typography>
          )}
        </Box>

        <Typography variant="h6" color={colours.grey.light} component="p">
          {profile}
        </Typography>
      </Box>
    </BrandedBox>
  );
};

export default ProfileCard;
