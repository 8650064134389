import React, { FC } from "react";
import { Chip, ChipProps, Box } from "@mui/material";
import { colours } from "~/theme";

type props = {
  icon?: React.ReactElement;
  sx?: any;
  label: string;
};

const PillWithIcon: FC<props & ChipProps> = ({ icon, sx, label, ...props }) => {
  return (
    <Box sx={{ position: "relative", ...sx }}>
      {icon && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            zIndex: 1,
            right: { xs: 24, lg: 36 },
            "> div": {
              // increase the icon size on desktop
              width: { lg: 44 },
              height: { lg: 44 },
            },
          }}
        >
          {icon}
        </Box>
      )}
      <Chip
        label={label}
        {...props}
        sx={{
          color: colours.navy.main,
          bottom: "0px",
          width: "100%",
          position: "absolute",
          top: "auto",
          borderRadius: "36px 30px 30px 0px",
        }}
      />
    </Box>
  );
};

export default PillWithIcon;
