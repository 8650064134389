import React from "react";
import { createRoot } from "react-dom/client";
import { Auth } from "@aws-amplify/auth";
import App from "./App";
import Providers from "./Providers";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import "./index.css";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "local",
  integrations: [
    new Sentry.BrowserTracing({
      // React Router v6 integration - https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
    new CaptureConsoleIntegration({
      // Console.error will also be captured in Sentry
      levels: ["error"],
    }),
  ],
  // Captures 40% tracing of events on production - can tweak in future
  tracesSampleRate:
    process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 0.4 : 1.0,
  // Captures replays for 10% of all sessions on production
  replaysSessionSampleRate:
    process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 0.1 : 0,
  // Captures replays on error for 100% of all sessions only on production
  replaysOnErrorSampleRate:
    process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 1.0 : 0,
});

// set up amplify to look at the correct Cognito instance
Auth.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
});

(async function () {
  // avoid flicker of incorrect auth state by running this before rendering the App
  const user = await Auth.currentAuthenticatedUser().catch(() => null);

  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <Providers user={user}>
        <App />
      </Providers>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
