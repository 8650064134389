import { Member } from "@sendbird/chat/groupChannel";
import { EveryMessage } from "@sendbird/uikit-react/types/types";

export const enum SessionState {
  Loading = "loading",
  Waiting = "waiting",
  Started = "started",
  Running = "running",
  Ended = "ended",
}

export const enum MessageCustomType {
  StartMsg = "start_message",
  EndMsg = "end_message",
  PeerWorkerLeft = "peerworker_left_message",
}

export type SessionEndType =
  | "peerworker-survey"
  | "peerworker-locked"
  | "helpseeker";

// add a label to the send button for accessibility (and remove tabbing onto the "inner iconbutton") as
// Sendbird uikit (v3) out of the box doesnt include this
// observe when changes to the message input dom - send button only appears
// when text has been input
export const messageObserver = new MutationObserver(() => {
  const sendButton = document.querySelector(
    ".sendbird-message-input--send.sendbird-iconbutton"
  );
  const innerButton = document.querySelector(
    ".sendbird-icon.sendbird-icon-send.sendbird-icon-color--primary"
  );
  sendButton?.setAttribute("aria-label", "Send");

  innerButton?.removeAttribute("tabindex");
  innerButton?.removeAttribute("role");
});

export type GroupChannelUserMessage = EveryMessage & {
  sender: Member; // `_sender` property is actually `sender` to get the userId. Sendbird types are not consistent with what is being returned in the app
};

export const scrollToLatestMessage = () => {
  setTimeout(() => {
    const notificationElement = document.getElementsByClassName(
      "sendbird-notification"
    )[0];

    const messageContainer = document.getElementsByClassName(
      "sendbird-conversation__messages-padding" // Sendbird message container div class
    )[0];

    // If there is no new message notification, scroll to the latest message
    if (!notificationElement && messageContainer?.scrollHeight) {
      messageContainer.scrollTop = messageContainer?.scrollHeight;
    }
  }, 500); // 500ms to wait for messages to render in the DOM
}

export const atLatestMessage = (toScrollElement?: Element | null) => {
  toScrollElement ||= document.querySelector(".sendbird-conversation__messages-padding");
  if (!toScrollElement) return false;

  return Math.abs(toScrollElement.scrollTop - (toScrollElement.scrollHeight - toScrollElement.clientHeight)) <= 100;
}
  
