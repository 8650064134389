import loadScript from "load-script";

export const loadRecaptcha = async (): Promise<void> => {
  // skip loading if it already exists
  if (typeof grecaptcha !== "undefined") return;

  return new Promise((res) =>
    loadScript(
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
      () => res()
    )
  );
};

export const buildRecaptcha = async (): Promise<string> =>
  new Promise((res, rej) =>
    // load the script
    loadRecaptcha().then(() => {
      // wait for it to become ready
      grecaptcha.ready(function () {
        // generate the captcha value for serverside validation
        grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, {
            action: "validate_captcha",
          })
          .then(res)
          .catch(rej);
      });
    })
  );
