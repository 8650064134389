import React, { useState } from "react";
import PeerWorkerHeaderButtons from "./PeerWorkerHeaderButtons";
import HeaderButton from "./HeaderButton";
import { SessionEndType, SessionState } from "~/utils/chat";
import { chatStyles } from "./styles";
import { Box } from "@mui/material";
import cn from "clsx";
import InfoMessage, { InfoContent } from "./InfoMessage";
import ChatActions from "./ChatActions";
import { GroupChannel } from "@sendbird/uikit-react/GroupChannel";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import type { GroupChannel as GroupChannelType } from "@sendbird/chat/groupChannel";
import ThinkingButton from "~/components/chat/ThinkingButton";
import HideMessageButton from "~/components/chat/HideMessageButton";
import type { UserMessageCreateParams } from "@sendbird/chat/message";
import { CustomMessage } from "./CustomMessage";
import { format } from "date-fns";
import { CustomMessageInput } from "./CustomMessageInput";

interface ChatWindowProps {
  channel: GroupChannelType;
  channelUrl: string;
  isStaffMember: boolean;
  sessionState: SessionState;
  isLockClicked: boolean;
  user: User | null;
  setLeaveModal: (value: boolean) => void;
  setHowChatEnded: (value: SessionEndType) => void;
  trackMessageSend: (
    messageParams: UserMessageCreateParams
  ) => UserMessageCreateParams;
  isConsentModalOpen: boolean;
  setIsConsentModalOpen: (value: boolean) => void;
  surveyCompleteOrExited: boolean;
}

export default function ChatWindow({
  channel,
  channelUrl,
  isStaffMember,
  sessionState,
  isLockClicked,
  user,
  setLeaveModal,
  setHowChatEnded,
  trackMessageSend,
  setIsConsentModalOpen,
  surveyCompleteOrExited,
}: ChatWindowProps): JSX.Element {
  const [showInfo, setShowInfo] = useState<InfoContent | null>("buttonInfo"); // info popup is for the HS only
  const globalStore = useSendbirdStateContext();
  const [visibleOverrides, setVisibleOverrides] = useState<number[]>([]);
  const [hideMessagesBefore, setHideMessagesBefore] = useState(0);
  const [messageSending, setMessageSending] = useState<boolean>(false);

  const addVisibleMessage = (messageId: number) => {
    setVisibleOverrides((messageIds) => [...messageIds, messageId]);
  };
  const onToggleHideMessages = () => {
    setHideMessagesBefore((hiddenDate) => (hiddenDate ? 0 : Date.now()));
    setVisibleOverrides([]);
  };

  return (
    <>
      {isStaffMember && (
        <PeerWorkerHeaderButtons
          sessionState={sessionState}
          isLocked={isLockClicked}
          onSurveyClick={() => {
            setLeaveModal(true);
            setHowChatEnded("peerworker-survey");
          }}
          onLockClick={() => {
            if (!channel) return;
            if (channel.isFrozen) {
              channel.unfreeze();
            } else {
              channel.freeze();
            }
          }}
        />
      )}
      {!isStaffMember && (
        <HeaderButton
          onClick={() => {
            const sessionEndedNoSurvey =
              sessionState === SessionState.Ended && !surveyCompleteOrExited;
            if (sessionEndedNoSurvey) {
              // prompt contact eval & survey
              setIsConsentModalOpen(true);
              // if past chat/survey complete, leave without further prompts
            } else setLeaveModal(true);
            setHowChatEnded("helpseeker");
          }}
          buttonLabel="Leave"
        />
      )}
      <Box
        sx={[
          chatStyles,
          {
            "&.info-message-active .sendbird-conversation__messages": {
              //  different padding for the different message sizes
              pb:
                showInfo && showInfo === "buttonInfo"
                  ? { xs: 20, sm: 15, md: 15 }
                  : { xs: 29, sm: 20, md: 12 },
            },
          },
        ]}
        className={cn(
          `theme--${globalStore.config.theme}`,
          !isStaffMember && showInfo && "info-message-active"
        )}
        // avoid privacy issues - don't capture text from inside the chat session
        data-heap-redact-text="true"
      >
        {!isStaffMember && (
          <>
            {showInfo === "buttonInfo" ? (
              <ChatActions
                message={<InfoMessage infoContent="buttonInfo" />}
                sx={{ borderRadius: { xs: "15px" } }}
                onClose={() => setShowInfo("feedback")}
              />
            ) : showInfo === "feedback" ? (
              <ChatActions
                message={<InfoMessage infoContent="feedback" />}
                onClose={() => setShowInfo(null)}
              />
            ) : null}
          </>
        )}
        <ThinkingButton
          isLocked={isLockClicked}
          channel={channel}
          sx={{ p: 0, mr: 2 }}
        />
        <HideMessageButton
          isShowing={!hideMessagesBefore}
          onClick={onToggleHideMessages}
          sx={{ p: 0, mr: 2 }}
        />
        <GroupChannel
          isReactionEnabled={!channel.isFrozen}
          showSearchIcon
          disableUserProfile
          channelUrl={channelUrl}
          renderChannelHeader={() => <></>} // hide header
          onBeforeSendUserMessage={async (messageParams) => {
            setMessageSending(true);
            setTimeout(() => {
              setMessageSending(false);
            }, 500); // 500ms to wait for message to send (no after message sent event in Sendbird)
            return trackMessageSend(messageParams);
          }}
          scrollBehavior="auto" // default
          renderMessage={({ message }) => {
            return (
              <CustomMessage
                sessionJoinedDate={format(
                  new Date(channel.lastMessage?.createdAt || ""), // channel.joinedAt would be ideal but seems to return an invalid date
                  "MMMM dd, yyyy"
                )}
                message={message}
                user={user}
                visibleOverrides={visibleOverrides}
                hideMessagesBefore={hideMessagesBefore}
                addVisibleMessage={addVisibleMessage}
              />
            );
          }}
          renderMessageInput={() => (
            <CustomMessageInput messageSending={messageSending} />
          )}
          replyType="NONE"
        />
      </Box>
    </>
  );
}
