import React from "react";
import { GlobalStyles, Theme } from "@mui/material";
import { colours } from "~/theme";
import { sendbirdColours } from "./sendbirdColourOverrides";

// TODO: Verify all these global chat styles are still applied
export const globalChatStyles = (
  <GlobalStyles styles={{ ":root": sendbirdColours }} />
);
const openGraphMessageSelectors =
  "& .sendbird-og-message-item-body__text-bubble, & .sendbird-og-message-item-body__description";

export const chatStyles = {
  // fix the styling issues created by MUI CssBaseline

  height: "100%",
  lineHeight: 1,

  "&.info-message-active .sendbird-conversation__messages": {
    pb: { xs: 29, sm: 20, md: 15 },
  },

  /* Sendbird customisations */

  '&.theme--dark .sendbird-icon-color--on-background-2 [class$="fill"]': {
    fill: "var(--sendbird-dark-onlight-02)",
  },

  // Entire container
  "&.theme--dark .sendbird-conversation": {
    backgroundColor: colours.background.dark,
  },

  "&.theme--dark .sendbird-tooltip__text": {
    color: "var(--sendbird-tooltip-text-color) !important",
  },
  "&.theme--light .sendbird-reaction-badge--selected .sendbird-reaction-badge__inner__count":
    {
      color: colours.text.light,
    },

  "&.theme--light .sendbird-conversation": {
    backgroundColor: colours.buff.main,
  },
  "&.theme--light .sendbird-notification .sendbird-notification__text": {
    color: colours.text.light,
  },
  "& .sendbird-msg-hoc": {
    marginBottom: "16px !important", // sb sets this as an element style
  },

  "& .sendbird-conversation__messages-padding": {
    overflow: "scroll",
    paddingTop: "14px", // Add padding to the top of the chat container so first message isn't sitting on the header
  },

  "& .sendbird-emoji-reactions .sendbird-context-menu": {
    display: "inline-flex !important",
  },

  "& .sendbird-message-status__icon.sendbird-message-status__icon": {
    display: "none",
  },
  "& .sendbird-conversation.sendbird-conversation": {
    border: "none",
  },

  "& .sendbird-message-input--attach": {
    display: "none !important",
  },

  "& .sendbird-message-content": {
    px: [1, 3],
  },
  /* Outgoing messages: */

  "& .sendbird-message-hoc__message-content.sendbird-message-content.outgoing":
    {
      width: "100%",
    },

  "& .sendbird-text-message-item-body.outgoing.outgoing": {
    bgcolor: colours.ice.main,
    borderRadius: "16px 16px 0px 16px",
    "&.hover,&.mouse-hover": {
      bgcolor: colours.ice.dark,
    },
    "& .sendbird-text-message-item-body__message": {
      color: colours.text.dark,
    },
  },
  /* Incoming messages: */

  "& .sendbird-message-content__middle > span": {
    color: "primary.main",
    fontWeight: 700,
  },

  "& .sendbird-message-hoc__message-content.sendbird-message-content.incoming":
    {
      width: "100%",
    },
  "& .sendbird-text-message-item-body.incoming": {
    bgcolor: "common.white",
    borderRadius: "16px 16px 16px 0px",
    "&.hover,&.mouse-hover": {
      bgcolor: colours.white.dark,
    },
    "& .sendbird-text-message-item-body__message": {
      color: colours.text.dark,
    },
  },

  "& .sendbird-text-message-item-body__message": {
    wordBreak: "normal",
    wordWrap: "break-word",
  },

  "& .sendbird-message-content-reactions.primary": {
    bgcolor: "transparent",
    "&.hover,&.mouse-hover": {
      bgcolor: "transparent",
    },
  },
  "& .sendbird-message-content-reactions": {
    bgcolor: "transparent",
    "&.hover,&.mouse-hover": {
      bgcolor: "transparent",
    },
  },

  "& .sendbird-reaction-badge--selected": {
    backgroundColor: colours.plum.main,
    border: colours.plum.main,
  },

  "& .sendbird-reaction-badge, & .sendbird-reaction-badge--is-add, & .sendbird-reaction-badge--selected":
    {
      "&:hover, &:focus": {
        borderColor: "text.primary",
      },
    },

  // restyle iconbutton pressed state when menu/reaction popup is open
  "& .sendbird-iconbutton--pressed, & .sendbird-reactions--pressed": {
    backgroundColor: colours.grey[100],
  },
  "&.theme--dark .sendbird-iconbutton--pressed, &.theme--dark .sendbird-reactions--pressed":
    {
      backgroundColor: colours.grey.main,
    },

  "&.theme--dark .sendbird-message-content__middle > span": {
    color: "common.white",
  },

  //Incoming message content text
  "& .sendbird-message-content__middle > div > div > span": {
    color: "primary.dark",
    fontWeight: 400,
    fontSize: "12px",
  },

  "& .sendbird-conversation__padding": {
    paddingTop: "8px", // Add padding to the top of the chat container so first message isn't sitting on the header
  },

  // Message separators
  "& .sendbird-separator .sendbird-color--onbackground-4--background-color": {
    bgcolor: "primary.main",
  },

  "& .sendbird-separator": {
    marginTop: "24px",
  },

  "& .sendbird-separator__text > span": {
    fontWeight: 700,
    fontSize: "12px",
  },
  // separator text - contains date
  "& .sendbird-label .sendbird-label--caption-2 .sendbird-label--color-onbackground-2":
    {
      color: "navy.main",
    },

  // Footer and inputs
  "& .sendbird-conversation__footer": {
    pt: 1,
    pb: 1,
    pr: 0,
    pl: 0,

    bgcolor: "navy.main",

    "& > form": {
      display: "flex",
      alignItems: "flex-end",
      pr: [2, 3],
      pl: [1, 3],
      "& > button": {
        color: "common.white",
      },
    },

    "& .sendbird-message-input .sendbird-message-input--textarea": {
      overflowY: "hidden",
      borderRadius: "30px",
      bgcolor: colours.background.light,
      color: colours.text.dark,
      height: 40,
      py: 1,
      pr: 8,
      pl: 2,
      "&::selection": {
        backgroundColor: "var(--sendbird-light-background-200)",
      },
    },
    "& .sendbird-message-input .sendbird-message-input--send": {
      right: 6,
      bottom: 6,
      pl: 1,
      borderRadius: "50%",
      "&:focus": {
        backgroundColor: "var(--sendbird-light-background-100)",
      },
    },
    "& .sendbird-message-input--placeholder": {
      top: "10px",
      color: "var(--sendbird-light-onlight-03)",
    },

    "& > .sendbird-conversation__typing-indicator": {
      top: (theme: Theme) => theme.spacing(-3),
      bottom: "auto",
    },
    "& > .sendbird-conversation__footer__typing-indicator": {
      bottom: "66px", // Move typing indicator message to above chat input textfield to be visible
    },
    "& .sendbird-message-input-wrapper__message-input": {
      display: "flex", // Get thinking and hide icon buttons inline with message input
    },
  },

  // Message with embedded OpenGraph preview
  "& .sendbird-og-message-item-body": {
    [openGraphMessageSelectors]: {
      "& > span, & > a > span": {
        color: colours.text.dark,
        fontSize: 14,
        wordWrap: "break-word",
        display: "inline-block",
        whiteSpace: "normal",
      },
      "& > a > span": {
        textDecoration: "underline",
      },
    },

    "&.incoming": {
      [openGraphMessageSelectors]: {
        bgcolor: "common.white",
        "&:hover, &:focus": {
          bgcolor: colours.white.dark,
        },
      },
      "&.mouse-hover": {
        [openGraphMessageSelectors]: {
          bgcolor: colours.white.dark,
        },
      },
    },
    "&.outgoing": {
      [openGraphMessageSelectors]: {
        bgcolor: colours.ice.main,
        "&:hover, &:focus": {
          bgcolor: colours.ice.dark,
        },
      },
      "&.mouse-hover": {
        [openGraphMessageSelectors]: {
          bgcolor: colours.ice.dark,
        },
      },
    },
  },
};
