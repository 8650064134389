import React, { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import LoginForm from "~/components/auth/LoginForm";
import DefaultLayout from "./layouts/DefaultLayout";

type Props = {
  noRedirect?: boolean;
};
const LoginScreen: FC<Props> = ({ noRedirect = false }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSignupClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate("/signup", { state: location.state })
  }

  return (
    <DefaultLayout title="Log in" noHeaderColour sx={{ pt: 2 }}>
      <LoginForm
        noRedirect={noRedirect}
        onSignupClick={onSignupClick}
      />
    </DefaultLayout>
  );
};

export default LoginScreen;
