import React, { FC, PropsWithChildren } from "react";
import {
  Box,
  useTheme,
  Theme,
  Button,
  IconButton,
  Stack,
  Typography,
  BoxProps,
} from "@mui/material";
import { colours } from "~/theme";
import Logo from "./Logo";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Close, Menu } from "@mui/icons-material";

type Props = {
  urgentHelpUrl: string;
  menuOpen: boolean;
  hideHeaderActions?: boolean;
  handleToogle: () => void;
  sx?: BoxProps["sx"];
};

const SlimlineHeader: FC<PropsWithChildren<Props>> = ({
  children,
  urgentHelpUrl,
  menuOpen,
  hideHeaderActions,
  handleToogle,
  sx = [],
}) => {
  const theme = useTheme();
  return (
    <ThemeProvider
      theme={(t: Theme) =>
        createTheme({
          ...t,
          breakpoints: {
            values: {
              ...theme.breakpoints.values,
              md: 1440, // Show the ReachOut logo on screens wider than 1440px, hide it otherwise
            },
          },
        })
      }
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={[{ px: 3 }, ...(Array.isArray(sx) ? sx : [sx])]}
        height="72px"
        bgcolor={colours.background.dark}
      >
        <Logo />
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Box>{children}</Box>

          <Button
            color="urgent"
            component="a"
            href={urgentHelpUrl}
            target="_blank"
            sx={{
              height: "40px",
            }}
          >
            <Typography variant="body1" lineHeight="1">
              Urgent help
            </Typography>
          </Button>

          {!hideHeaderActions && (
            <Box id="header-menu" sx={{}}>
              <IconButton
                id="menu-toggle"
                onClick={handleToogle}
                color="inherit"
                aria-label={menuOpen ? "Close menu" : "Open menu"}
                aria-expanded={menuOpen}
                sx={{
                  p: 0,
                }}
              >
                {menuOpen ? (
                  <Close />
                ) : (
                  <Menu style={{ width: 32, height: 32 }} />
                )}
              </IconButton>
            </Box>
          )}
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default SlimlineHeader;
