import React, { FC, useEffect } from "react";

import {
  Box,
  BoxProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

type Props = {
  name: string;
  defaultValue: string;
  id?: string;
  boxSx?: BoxProps["sx"];
} & TextFieldProps;

const FormSelect: FC<Props> = ({
  name,
  label,
  boxSx,
  id: idProp,
  ...props
}) => {
  const { register, watch, formState, setValue } = useFormContext();
  const { isSubmitted, errors, touchedFields } = formState;

  // default field id to name value. id is used to link a11y labels
  const id = idProp || name;

  const errorMessage = get(errors, `${name}.message`, get(errors, name));
  const hasError = Boolean(
    (isSubmitted || get(touchedFields, name)) && errorMessage
  );

  useEffect(() => {
    register(name);
  }, [register, name]);
  const value = watch(name);

  return (
    <Box display="flex" flexDirection="column" sx={boxSx}>
      {label && (
        <Typography fontWeight="bold" component="label" htmlFor={id} mb={1}>
          {label}
          {props.required ? " *" : ""}
        </Typography>
      )}
      <TextField
        select
        value={value}
        onChange={(e) => setValue(name, e.target.value)}
        {...props}
        id={id}
        error={hasError}
        SelectProps={{
          ...props.SelectProps,
          // if an end-adornment is specified, hide the dropdown arrow
          ...(props.InputProps?.endAdornment
            ? { IconComponent: () => null }
            : {}),
        }}
      />
      {hasError && (
        <ErrorMessage message={errorMessage?.toString()} sx={{ pt: 0 }} />
      )}
    </Box>
  );
};

export default FormSelect;
