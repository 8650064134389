import { Theme } from "@mui/material";
import { CSSSelectorObject } from "@mui/system";

export const svgAnimation: CSSSelectorObject<Theme> = {
  "@keyframes hideshow": {
    "0%": { opacity: 0 },
    "10%": { opacity: 0.5 },
    "50%": { opacity: 0.5 },
    "60%": { opacity: 0 },
  },
  "@keyframes hideshow-full": {
    "0%": { opacity: 0 },
    "10%": { opacity: 1 },
    "50%": { opacity: 1 },
    "70%": { opacity: 0 },
  },
  "& #smoke>g:nth-of-type(1),#waterfall>g:nth-of-type(1)": {
    animationDelay: "0s",
  },
  "& #smoke>g:nth-of-type(2),#waterfall>g:nth-of-type(2)": {
    animationDelay: "1.5s",
  },
  "& #waterfall>g:nth-of-type(2)": {
    animationDelay: "1s",
  },
  "& #smoke>g,#waterfall>g": {
    animation: "hideshow 3s linear 0s infinite",
    opacity: 0,
  },
  "& #waterfall>g": {
    animationName: "hideshow-full",
    animationDuration: "2s",
  },
};
