import React, { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";

type Props = {
  message?: string;
} & TypographyProps;

const ErrorMessage: FC<Props> = ({ message, ...props }) => {
  if (!message) return null;
  return (
    <Typography color="error" {...props}>
      &times;
      {message}
    </Typography>
  );
};

export default ErrorMessage;
