import React, { FC, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { colours } from "~/theme";
import Progress from "~/components/Progress";
import ROIconButton from "~/components/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";

import SliderStep from "./SliderStep";
import ButtonStep from "./ButtonStep";
import RatingStep from "./RatingStep";
import { PostCheckInNames, postCheckInValues } from "~/content/checkInData";
import FinishStep from "./FinishStep";
import axios from "axios";
import { Auth } from "@aws-amplify/auth";
import { useParams } from "react-router-dom";

type Props = {
  open: boolean;
  handleClose: React.MouseEventHandler;
};

const SurveyDialog: FC<Props> = ({ open, handleClose }) => {
  const { sessionId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [easedFromDifficulty, setEasedFromDifficulty] = React.useState<
    boolean | undefined
  >(undefined);

  const handleEasedFromDifficulty = (value: boolean) =>
    setEasedFromDifficulty(value === easedFromDifficulty ? undefined : value);

  const sliderValuesList = Object.keys(postCheckInValues) as PostCheckInNames[];

  const [checkIn, setCheckIn] = React.useState<{
    numberValue: number | undefined;
    textValue: PostCheckInNames;
    touched: boolean;
  }>({
    numberValue: undefined,
    textValue: "Skipped check-in",
    touched: false,
  });

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setCheckIn({
      numberValue: newValue as number,
      textValue: sliderValuesList[newValue as number],
      touched: true,
    });
  };
  const [surveySubmitted, setSurveySubmitted] = React.useState(false);
  const [skippedRating, setSkippedRating] = React.useState(false);

  const [ratingValue, setRatingValue] = React.useState<number | null>(null);
  const [feedbackText, setFeedbackText] = React.useState<string>("");
  const steps = [
    {
      title: "How do you feel after your chat?",
      component: "slider",
    },
    {
      title:
        "Since your chat, do you feel like it'll be easier for you to talk about difficult things you're facing?",
      component: "button",
    },

    {
      title: "How likely are you to recommend this service to people like you?",
      component: "voting",
    },
  ];

  const { title = "", component } = steps[activeStep];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleEarlyExit = useCallback(() => {
    if (surveySubmitted) return;

    // values for more readable heap data
    const stepValues = [
      "post-chat-checkin",
      "post-chat-sif-relief",
      "post-chat-satisfaction-score",
    ];

    try {
      window.heap.track("checkin-after-survey", {
        earlyExitStep:
          activeStep <= 2 && activeStep >= 0 ? stepValues[activeStep] : "",
      });
    } catch (err) {
      if (err instanceof Error)
        console.error("Error submitting survey early exit: ", err.message);
    }
  }, [activeStep, surveySubmitted]);

  const handleFinishSurvey = useCallback(async () => {
    if (surveySubmitted) return;
    try {
      window.heap.track("checkin-after-survey", {
        postChatCheckin: checkIn.textValue,
        postChatSIFRelief:
          easedFromDifficulty !== undefined ? easedFromDifficulty : "skipped", // could be false
        postChatSatisfactionScore:
          !skippedRating && ratingValue !== null ? ratingValue : "skipped",
        feedbackTextSkipped: feedbackText === "",
      });

      // Storing the recommendation reason feedback text outside of Heap due to data sensitivity reasons
      if (ratingValue !== null && feedbackText) {
        await axios.post(
          `${process.env.REACT_APP_API_BASE}/survey`,
          {
            sessionId: sessionId,
            recommendationRating: ratingValue,
            recommendationReason: feedbackText,
          },
          {
            headers: {
              Authorization: (await Auth.currentSession())
                .getIdToken()
                .getJwtToken(),
            },
          }
        );
      }
      setSurveySubmitted(true);
    } catch (err) {
      if (err instanceof Error)
        console.error("Error submitting survey responses: ", err.message);
    }
  }, [
    checkIn.textValue,
    easedFromDifficulty,
    feedbackText,
    ratingValue,
    sessionId,
    skippedRating,
  ]);

  const handleSkip = () => {
    switch (activeStep) {
      case 0:
        setCheckIn({
          numberValue: undefined,
          textValue: "Skipped check-in",
          touched: false,
        });
        handleNext();
        break;
      case 1:
        setEasedFromDifficulty(undefined);
        handleNext();
        break;
      case 2:
        setSkippedRating(true);
        handleFinishSurvey();
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClickStep = (stepIndex: number) => {
    setActiveStep(stepIndex);
  };

  const isNextButtonDisabled = useMemo(() => {
    // lock next/finish btns based on inputs
    switch (activeStep) {
      case 0:
        if (checkIn.numberValue !== undefined) return false;
        return true;
      case 1:
        if (easedFromDifficulty !== undefined) return false;
        return true;
      case 2:
        if (ratingValue !== null) return false;
        return true;
      default:
        return true;
    }
  }, [activeStep, checkIn.numberValue, easedFromDifficulty, ratingValue]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      role="dialog"
      aria-label="end of chat survey dialog"
      sx={{ backgroundColor: colours.navy.main }}
      PaperProps={{
        sx: {
          backgroundColor: colours.navy.main,
          color: colours.white.main,
          backgroundImage: "none",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          onClick={async (event) => {
            // if user exits via close button on the finish step
            // then still submit the survey
            if (activeStep === 2) {
              await handleFinishSurvey();
              handleClose(event);
            } else {
              // user quit before survey complete
              // so submit early exit event
              // and do not re-prompt survey
              handleEarlyExit();
              handleClose(event);
            }
          }}
          aria-label="Close dialog"
          sx={(theme) => ({
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: theme.zIndex.mobileStepper,
          })}
        >
          <CloseRounded fontSize="inherit" sx={{ color: colours.white.main }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!surveySubmitted ? (
          <Stack
            pt={{ xs: 5, sm: 4 }}
            color="white"
            justifyContent={{ xs: "flex-start", md: "center" }}
            alignItems="center"
            height="100%"
          >
            <Progress
              steps={steps}
              activeStep={activeStep}
              onClickStep={onClickStep}
            />

            <Stack
              spacing={{ xs: 1, md: 3 }}
              alignItems="center"
              py={3}
              sx={{
                backgroundColor: colours.navy.main,
                borderRadius: "16px",
                color: colours.white.main,
              }}
              maxWidth="md"
              width="100%"
              height="100%"
            >
              <Typography
                variant="h3"
                component="h1"
                fontWeight={400}
                mb={4}
                sx={{ color: colours.white.main }}
                textAlign={{ xs: "left", md: "center" }}
              >
                {title}
              </Typography>
              {component === "slider" && (
                <SliderStep
                  checkIn={checkIn}
                  handleSliderChange={handleSliderChange}
                />
              )}
              {component === "button" && (
                <ButtonStep
                  easedFromDifficulty={easedFromDifficulty}
                  handleEasedFromDifficulty={handleEasedFromDifficulty}
                />
              )}
              {component === "voting" && (
                <RatingStep
                  ratingValue={ratingValue}
                  setRatingValue={setRatingValue}
                  feedbackText={feedbackText}
                  setFeedbackText={setFeedbackText}
                />
              )}
            </Stack>
          </Stack>
        ) : (
          <FinishStep handleClose={handleClose} />
        )}
      </DialogContent>

      {!surveySubmitted && (
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            width={"100%"}
            px={1}
            pb={1}
          >
            <Box>
              {activeStep !== 0 && (
                <ROIconButton label="back" onClick={handleBack}>
                  <ArrowBackIcon />
                </ROIconButton>
              )}
            </Box>
            <Box>
              <Button
                aria-label="Skip"
                variant="text"
                onClick={handleSkip}
                sx={{
                  marginRight: 1,
                  color: "white",
                  fontWeight: "unset",
                  textDecoration: "none",
                }}
              >
                Skip
              </Button>
              {activeStep !== steps.length - 1 ? (
                <ROIconButton
                  label="forward"
                  onClick={handleNext}
                  disabled={isNextButtonDisabled}
                >
                  <ArrowForwardIcon />
                </ROIconButton>
              ) : (
                <ROIconButton
                  label="finish"
                  onClick={handleFinishSurvey}
                  disabled={isNextButtonDisabled}
                >
                  <DoneIcon />
                </ROIconButton>
              )}
            </Box>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SurveyDialog;
