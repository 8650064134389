import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { FC } from "react";
import { colours } from "~/theme";
import PillWithIcon from "../PillWithIcon";
import HeartIcon from "@mui/icons-material/Favorite";

const links = [
  {
    link: "/booking/before",
    text: "Book another chat",
  },
  {
    link: "https://au.reachout.com/challenges-and-coping#nextstep",
    text: "Check out more support options",
  },
  {
    link: "https://forums.au.reachout.com/?sort=Top&filter=everything",
    text: "Connect with others in our safe Online Community",
  },
  {
    link: "https://au.reachout.com/mental-wellbeing",
    text: "Explore our mental wellbeing resources",
  },
];

type Props = {
  handleClose: React.MouseEventHandler;
};

const FinishStep: FC<Props> = ({ handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      direction="column"
      height="100%"
      justifyContent={{ xs: "space-between", md: "space-evenly" }}
      alignItems="center"
      spacing={5}
      px={{ xs: 1, md: 15 }}
      py={2}
    >
      <Stack
        alignItems="center"
        spacing={{ xs: 2, md: 4 }}
        pt={{ xs: 0, md: 2 }}
      >
        <PillWithIcon
          label="Thank you"
          sx={{
            height: "98px",
            width: "242px",
            ".MuiChip-root": {
              backgroundColor: colours.white.main,
              height: "70px",
              fontSize: "32px",
              fontWeight: 700,
            },
            ".MuiBox-root": {
              height: "44px",
              width: "44px",
              backgroundColor: colours.peri.main,
            },
          }}
          icon={
            <HeartIcon
              sx={{
                color: colours.white.main,
                width: "70%",
                height: "70%",
              }}
            />
          }
        />
        <Typography
          color={colours.white.main}
          variant="h3"
          align="center"
          sx={{
            fontSize: isMobile ? 18 : 24,
          }}
          maxWidth="md"
        >
          Your answers will help us to improve the PeerChat service for other
          young people around Australia.
        </Typography>
      </Stack>

      <Box>
        <Typography variant="body1" color={colours.white.main}>
          What can I do now?
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            color: colours.peri.main,
            display: "inline-block",
            textAlign: isMobile ? "left" : "center",
            paddingLeft: isMobile ? 3 : "unset",
          }}
        >
          {links.map(({ link, text }, index) => (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <Typography
                component={Link}
                color={colours.peri.main}
                href={link}
              >
                {text}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <Button
          color="plum"
          onClick={(event) => {
            handleClose(event);
          }}
        >
          Finish Survey and Close
        </Button>

        <Typography
          variant="body1"
          color={"white"}
          align="center"
          sx={{
            fontSize: isMobile ? 16 : 18,
            py: 2,
          }}
        >
          Do you need{" "}
          <Typography
            component={Link}
            color={colours.peri.main}
            href="https://au.reachout.com/urgent-help"
          >
            urgent help?
          </Typography>
        </Typography>
      </Box>
    </Stack>
  );
};

export default FinishStep;
