import phone from "phone";

export const normaliseUsername = (username: string): string => {
  return (
    (username.includes("@")
      ? username
      : phone(username, { country: "AU" }).phoneNumber) || ""
  );
};

export const emailOrPhoneParams = (usercontact: string): any => {
  return usercontact.includes("@")
    ? { email: usercontact }
    : { phone_number: phone(usercontact, { country: "AU" }).phoneNumber || "" };
};

export type AuthState =
  | "signUp"
  | "loggedOut"
  | "loggedIn"
  | "resetRequired" // after login, if used temporary password
  | "forgotPasswordRequest" // enter phone/email to receive code
  | "forgotPassword" // form to enter code and set new pw
  | "changePassword"; // enter existing pw + new pw

export type AuthScreenProps = {
  setAuthState: (string: AuthState) => void;
  setMessage: (result: string) => void;
  onChange: (user: any) => void;
  setUser: (user: any) => void;
  user: any;
};
