import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import { Route, Routes, Navigate } from "react-router-dom";

import DashboardScreen from "~/screens/DashboardScreen";
import SessionScreen from "~/screens/SessionScreen";
import SignupScreen from "~/screens/SignupScreen";
import LoginScreen from "~/screens/LoginScreen";
import BookingSuccess from "~/screens/BookingSuccess";
import BookingAvailability from "~/screens/BookingAvailability";
import ForgotPasswordScreen from "~/screens/ForgotPasswordScreen";
import ManageBookingScreen from "./screens/ManageBookingScreen";
import MyDetailsScreen from "./screens/MyDetailsScreen";
import IncidentInfoScreen from "./screens/IncidentInfoScreen";

import AdminScreen from "~/screens/AdminScreen";
import InboxScreen from "./screens/InboxScreen";

import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { AuthContext } from "~/components/providers/AuthProvider";
import OverseasUserIntercept from "./components/OverseasUserIntercept";
import * as Sentry from "@sentry/react";
import PasswordResetRequiredScreen from "./screens/PasswordResetRequiredScreen";

// Allows Sentry to reach React Router context - https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// App can be used to set the overall layout and common things like header/footer
function App() {
  const { isLoggedIn, isAdmin, isStaffMember, isSeniorPeerWorker } =
    useContext(AuthContext);
  const [hideHeaderActions, setHideHeaderActions] = useState(false);

  return (
    <Box sx={{ height: "100%", overflow: "hidden" }} id="main-app-container">
      <Header hideHeaderActions={hideHeaderActions} />

      <OverseasUserIntercept
        onDetectOverseas={() => setHideHeaderActions(true)}
      />

      <Box
        id="main-content"
        component="main"
        sx={{
          height: [`calc(100% - 112px) `],
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SentryRoutes>
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />

          <Route
            path="/password-reset-required"
            element={<PasswordResetRequiredScreen />}
          />
          {isLoggedIn ? (
            <>
              <Route path="/" element={<DashboardScreen />} />
              <Route path="/my-details" element={<MyDetailsScreen />} />
              <Route path="/session/:sessionId" element={<SessionScreen />} />
              <Route
                path="/booking/availability"
                element={<BookingAvailability />}
              />
              <Route path="/booking/success" element={<BookingSuccess />} />
              <Route
                path="/booking/:bookingId"
                element={<ManageBookingScreen />}
              />
              <Route
                path="/booking/before"
                element={<Navigate to="/booking/availability" replace />}
              />
            </>
          ) : (
            <>
              {/* this is the new booking flow from peerchat landing page*/}
              <Route
                path="/booking/availability"
                element={<Navigate to="/signup" />}
              />
              <Route
                path="/booking/before"
                element={<Navigate to="/signup" />}
              />
              {/* show the login screen but stay on route afterwards */}
              <Route path="*" element={<LoginScreen noRedirect />} />
            </>
          )}

          {(isAdmin || isSeniorPeerWorker) && (
            <Route path="/admin" element={<AdminScreen />} />
          )}
          {isStaffMember && (
            <>
              <Route path="/inbox" element={<InboxScreen />} />
              <Route path="/inbox/:userId" element={<InboxScreen />} />
              <Route
                path="/incident/:userId"
                element={<IncidentInfoScreen />}
              />
            </>
          )}
          <Route path="*" element={<DashboardScreen notFound />} />
        </SentryRoutes>
        <Footer />
      </Box>
    </Box>
  );
}

export default App;
