import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { OverrideProps } from "@mui/material/OverridableComponent";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { get } from "lodash";

import ErrorMessage from "~/components/ErrorMessage";
import CountLimitIndicator from "./CountLimitIndicator";

type InputProps = {
  [key: string]: any;
};

type FormFieldTypeMap = {
  props: {
    name: string;
    id?: string;
    boxSx?: BoxProps["sx"];
    setErrorProp?: boolean;
    inputProps?: InputProps;
    includeCountLimitIndicator?: boolean;
  };
  defaultComponent: React.ElementType;
};
type FormFieldType = <
  C extends React.ElementType = React.ElementType<TextFieldProps>
>(
  props: {
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component?: C;
  } & OverrideProps<FormFieldTypeMap, C>
) => JSX.Element;

export type FormFieldProps = Parameters<FormFieldType>[0];

const FormField: FormFieldType = ({
  component: Component = TextField,
  name,
  boxSx,
  label,
  id: idProp,
  setErrorProp = true, // Autocomplete expects error on the renderInput, not the main component
  includeCountLimitIndicator,
  inputProps,
  ...props
}) => {
  const { register } = useFormContext();
  const { isSubmitted, errors, touchedFields } = useFormState();

  // default field id to name value. id is used to link a11y labels
  const id = idProp || name;

  const errorMessage = get(errors, `${name}.message`, get(errors, name));
  const hasError = Boolean(
    (isSubmitted || get(touchedFields, name)) && errorMessage
  );

  const limitedTextArea = includeCountLimitIndicator && inputProps?.maxLength;
  const watchValue = useWatch({ name });

  return (
    <Box display="flex" flexDirection="column" sx={boxSx}>
      {label && (
        <Typography fontWeight="bold" component="label" htmlFor={id} mb={1}>
          {label}
          {props.required ? " *" : ""}
        </Typography>
      )}
      <Component
        {...register(name)}
        {...props}
        id={id}
        error={setErrorProp ? hasError : undefined}
        {...{ inputProps: { ...inputProps } }}
      />
      {limitedTextArea && (
        <CountLimitIndicator
          currentValue={watchValue?.length || 0}
          maxValue={inputProps?.maxLength}
        />
      )}
      {hasError && (
        <ErrorMessage message={errorMessage?.toString()} sx={{ pt: 0 }} />
      )}
    </Box>
  );
};

export default FormField;
