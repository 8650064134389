import { Stop, PlayArrow } from "@mui/icons-material";
import { Box, IconButton, StackProps, Typography } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import { pxToRem } from "~/theme";
import Pulse, { PulseStage } from "./Pulse";

type Props = {
  inDuration: number;
  outDuration?: number;
  pauseDuration: number;
  inMessage?: string;
  outMessage?: string;
  pauseMessage?: string;
  stoppedMessage?: string;
  pauseAfterIn?: boolean;
  pauseAfterOut?: boolean;
  showStopButton?: boolean;
  start?: boolean;
  sx?: StackProps["sx"];
};

const Pulser: FC<Props> = ({
  inDuration,
  outDuration: rawOutDuration,
  pauseDuration,
  inMessage,
  outMessage,
  pauseMessage,
  stoppedMessage = "Stopped",
  pauseAfterIn = true,
  pauseAfterOut = false,
  showStopButton = true,
  start = true,
  sx,
}) => {
  // default to equal in/out duration if not set
  const outDuration = rawOutDuration || inDuration;

  const [stage, setStage] = useState<PulseStage>(start ? "in" : "stopped");
  const [playing, setPlaying] = useState(true);

  useEffect(() => {
    if (!start) {
      setStage("stopped");
    }
    if (!playing) {
      setStage("stopped");
      return;
    }
    let handle: any;
    switch (stage) {
      case "in":
        handle = setTimeout(
          () => setStage(pauseAfterIn ? "pause-out" : "out"),
          inDuration * 1000
        );
        break;
      case "out":
        handle = setTimeout(
          () => setStage(pauseAfterOut ? "pause-in" : "in"),
          outDuration * 1000
        );
        break;
      // pause-in transitions to in, pause-out transitions to out
      case "pause-in":
      case "pause-out":
        handle = setTimeout(
          () => setStage(stage.replace("pause-", "") as PulseStage),
          pauseDuration * 1000
        );
        break;
      case "stopped":
        if(start) setStage("in");
        break;
    }
    return () => clearTimeout(handle);
  }, [
    start,
    playing,
    stage,
    inDuration,
    outDuration,
    pauseDuration,
    pauseAfterIn,
    pauseAfterOut,
  ]);

  const message = useMemo(() => {
    switch (stage) {
      case "in":
        return inMessage;
      case "out":
        return outMessage;
      case "stopped":
        return stoppedMessage;
      default:
        return pauseMessage;
    }
  }, [stage, inMessage, outMessage, pauseMessage, stoppedMessage]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
        width: ["80vw", "50vw", "25vw"],
        height: ["80vw", "50vw", "25vw"],
        minWidth: 300,
        minHeight: 300,
        ...sx,
      }}
    >
      <Pulse
        direction={stage}
        inDuration={inDuration}
        outDuration={outDuration}
        pauseDuration={pauseDuration}
      />
      <Box
        sx={{
          position: "absolute",
          width: "50%",
          height: "50%",
          bgcolor: "common.white",
          borderRadius: "50%",
        }}
      ></Box>
      <Typography
        variant="h1"
        component="p"
        textAlign="center"
        sx={{
          zIndex: 1,
          color: "common.black",
          maxWidth: "50%",
          "&&": {
            fontSize: [pxToRem(26), pxToRem(32)],
          },
        }}
        aria-live="assertive" // TODO: this makes sense to me, but need to confirm a11y
      >
        {message}
      </Typography>
      {showStopButton && (
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "common.white",
            border: (theme) => `1px solid ${theme.palette.common.white}`,
          }}
          title={playing ? "Stop exercise" : "Start exercise"}
          onClick={() => setPlaying(!playing)}
        >
          {playing ? <Stop /> : <PlayArrow />}
        </IconButton>
      )}
    </Box>
  );
};

export default Pulser;
