import axios from "axios";
import { Auth } from "@aws-amplify/auth";

export const getCurrentToken = async () =>
  (await Auth.currentSession()).getIdToken().getJwtToken();

export const getSecureAxiosClient = (baseURL: string) => {
  const client = axios.create({ baseURL });
  client.interceptors.request.use(async (config) => {
    config.headers = config.headers || {};
    try {
      config.headers.Authorization = await getCurrentToken();
    } catch (ex) {}
    return config;
  });
  return client;
};
