import React, { FC, useContext, useCallback } from "react";
import { DarkMode, LightMode } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ThemePreferenceContext } from "~/components/providers/DarkModeManager";

const ThemeToggle: FC = () => {
  const { useDarkTheme, setUseDarkTheme } = useContext(ThemePreferenceContext);

  const toggleTheme = useCallback(
    () => setUseDarkTheme(!useDarkTheme),
    [useDarkTheme, setUseDarkTheme]
  );

  return (
    <IconButton
      id="theme-toggle"
      onClick={toggleTheme}
      aria-label={`Switch to ${useDarkTheme ? "light" : "dark"} theme`}
      size="large"
      color="inherit"
    >
      {useDarkTheme ? (
        <DarkMode />
      ) : (
        <LightMode />
      )}
    </IconButton>
  );
};

export default ThemeToggle;
