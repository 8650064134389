import loadScript from "load-script";

async function loadGeoip() {
  return new Promise<void>((res) =>
    loadScript("https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js", () =>
      res()
    )
  );
}

async function getUserCountry() {
  await loadGeoip();

  return new Promise<GeoIpResponse>((res, rej) => {
    window.geoip2.country(
      (result) => res(result),
      (err) => rej(err)
    );
  });
}

export async function userIsOverseas() {
  const countryRes = await getUserCountry();
  return countryRes.country.iso_code.toLowerCase() !== "au";
}
