import React, { FC, useState } from "react";
import DefaultLayout from "./layouts/DefaultLayout";
import { Alert, Stack, Typography, Link } from "@mui/material";
import LoadingButton from "~/components/LoadingButton";
import Auth from "@aws-amplify/auth";
import { normaliseUsername } from "~/components/auth/utils";
import { useLocation } from "react-router-dom";

const PasswordResetRequiredScreen: FC = () => {
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const {
    state: locationState = {} as { emailAddressOrMobileNumber?: string },
  } = useLocation();

  const onResetPassword = async () => {
    try {
      setIsLoading(true);
      setServerError("");

      if (!locationState?.emailAddressOrMobileNumber) {
        throw new Error(
          "Unable to request password reset. Please try again from the login page."
        );
      }

      await Auth.forgotPassword(
        normaliseUsername(locationState?.emailAddressOrMobileNumber)
      );
      setIsLoading(false);
      setIsCodeSent(true);
    } catch (ex) {
      setIsLoading(false);
      console.error(ex);
      // ignore enumeration error response - still visible in console / network traffic though
      if ((ex as Error).message?.includes("not found")) {
      } else {
        setServerError(
          (ex as Error).message || "Failed to request password reset"
        );
      }
    }
  };

  if (isCodeSent) {
    return (
      <DefaultLayout title="Code sent 👍" noHeaderColour sx={{ p: 3 }}>
        <Stack spacing={4}>
          <Typography fontWeight={700} data-heap-redact-text="true">
            We've sent you a code to {locationState?.emailAddressOrMobileNumber}
            .
          </Typography>
          <Typography>
            Use the link to reset your password and continue booking a session.
          </Typography>
          <Typography>
            If you don’t receive the code and are sure that you had a Peerchat
            account, you can{" "}
            <Link
              sx={{ cursor: "pointer" }}
              color="primary"
              onClick={() => setIsCodeSent(false)}
            >
              try again.
            </Link>
          </Typography>
        </Stack>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout
      title="Password update required"
      noHeaderColour
      sx={{ p: 3 }}
    >
      <Stack spacing={4}>
        <Typography fontWeight={700}>
          Please note: Due to a system change you will need to create a new
          password.
        </Typography>
        <Typography pb={2}>
          Press the button below and we'll send you a code which you can use to
          set a new password.
        </Typography>
        {serverError && <Alert severity="error">{serverError}</Alert>}
        <LoadingButton
          color="primary"
          type="submit"
          sx={{ maxWidth: { md: 200 } }}
          loading={isLoading}
          onClick={onResetPassword}
        >
          Send code
        </LoadingButton>
      </Stack>
    </DefaultLayout>
  );
};

export default PasswordResetRequiredScreen;
