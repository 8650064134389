import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";

import { getSecureAxiosClient } from "~/utils/auth";
import { withPrettyLabels } from "~/utils/validation";
import FormField from "~/components/FormField";
import LoadingButton from "~/components/LoadingButton";
import {
  currentOffset,
  currentTimeInTz,
  getCurrentTimezone,
} from "~/utils/date";

type Props = {
  messageType: "email" | "mobile";
  onSend: (msg: SentMessage) => void;
  user: ContactableUser;
};

interface MessageValues {
  subject?: string;
  message: string;
}
interface SentMessage extends MessageValues {
  to: string;
}

const messageSchema = withPrettyLabels(
  yup.object({
    subject: yup.string().required(),
    message: yup.string().required(),
  })
);

const client = getSecureAxiosClient(process.env.REACT_APP_API_BASE!);

const ContactForm: FC<Props> = ({ user, messageType, onSend }) => {
  const currentTimezone = getCurrentTimezone();
  const methods = useForm({
    resolver: yupResolver(messageSchema),
    defaultValues: {
      subject: messageType === "mobile" ? "unused" : "",
    },
  });

  const onSubmit = async (data: MessageValues) => {
    const apiData = {
      userId: user.id,
      subject: data.subject === "unused" ? undefined : data.subject,
      message: data.message,
    };

    await client.post("/message", apiData);
    onSend({ ...data, to: user.id });

    methods.reset();
  };

  const smsTextareaProps = {
    inputProps: { maxLength: 1600 },
    includeCountLimitIndicator: true,
  };

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        spacing={2}
      >
        {user.timezone !== currentTimezone && (
          <>
            <Typography fontWeight="bold">
              NOTE: This user is in a different timezone ({user.timezone}{" "}
              {currentOffset(user.timezone)})
            </Typography>
            <Typography fontWeight="bold">
              Current time there: {currentTimeInTz(user.timezone)}
            </Typography>
          </>
        )}
        {/* ignore subject field for text messages */}
        {messageType === "email" && (
          <FormField name="subject" label="Subject" required />
        )}

        {/* TODO: make this support sendgrid templates when in email mode */}
        <FormField
          name="message"
          label="Plain text message (no formatting)"
          rows={15}
          multiline
          required
          {...(messageType === "mobile" && { ...smsTextareaProps })}
        />

        <LoadingButton
          loading={methods.formState.isSubmitting}
          color="primary"
          type="submit"
          sx={{ alignSelf: "flex-start" }}
        >
          Send message
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default ContactForm;
