import { Box, Stack, TextField } from "@mui/material";
import React, { FC } from "react";
import { Voting } from "../Voting";
import { colours } from "~/theme";
import CountLimitIndicator from "../CountLimitIndicator";

type Props = {
  ratingValue: number | null;
  setRatingValue: React.Dispatch<React.SetStateAction<number | null>>;
  feedbackText: string;
  setFeedbackText: React.Dispatch<React.SetStateAction<string>>;
};

const maxFeedbackTextLength = 250;
const disabledCharactersRegex = /[<>/{};]/g;
const emojiRegex = /\p{Extended_Pictographic}/gu;

const RatingStep: FC<Props> = ({
  ratingValue,
  setRatingValue,
  feedbackText,
  setFeedbackText,
}) => {
  const ratings = Array.from(Array(11).keys());
  const feedbackTextLimitReached = feedbackText.length >= maxFeedbackTextLength;

  const handleRatingValue = (
    event: React.MouseEvent<HTMLElement>,
    newRating: number | null
  ) => {
    setRatingValue(newRating);
  };

  const handleFeedbackTextValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      !event.target.value.match(disabledCharactersRegex) &&
      !event.target.value.match(emojiRegex) &&
      event.target.value.length <= maxFeedbackTextLength
    ) {
      setFeedbackText(event.target.value);
    }
  };

  const handleFeedbackTextPaste = (
    event: React.ClipboardEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    const pastedInput = event.clipboardData.getData("text");

    if (!pastedInput) return;

    setFeedbackText((feedbackText) =>
      (feedbackText += pastedInput
        .replace(disabledCharactersRegex, "")
        .replace(emojiRegex, "")).slice(0, maxFeedbackTextLength)
    );
  };

  return (
    <>
      <Voting
        ratings={ratings}
        ratingValue={ratingValue}
        handleRatingValue={handleRatingValue}
      />
      {ratingValue !== null && (
        <Stack
          maxWidth="sm"
          alignSelf="center"
          aria-live="polite"
          sx={{
            width: "100%",
            color: colours.white.main,
            // Override MUI styles for white coloured text field regardless of light/dark mode
            ".MuiInput-root:after, .MuiInput-root:before, .MuiInput-root:active, .Mui-focused, .MuiInputLabel-root, .MuiFormLabel-root, .MuiInputBase-root":
              {
                color: `${colours.white.main} !important`,
                borderColor: `${colours.white.main} !important`,
              },
          }}
        >
          <TextField
            onPaste={handleFeedbackTextPaste}
            multiline
            error={feedbackTextLimitReached}
            id="feedback-text"
            label="Could you tell us why? (optional)"
            variant="standard"
            value={feedbackText}
            sx={{
              width: "100%",
            }}
            onChange={handleFeedbackTextValue}
          />
          <Box
            alignSelf="flex-end"
            sx={{
              ".MuiTypography-root": {
                color: feedbackTextLimitReached
                  ? colours.error.dark
                  : colours.white.main, // Override color to dark mode error color to pass contrast ratio
              },
            }}
          >
            <CountLimitIndicator
              currentValue={feedbackText.length}
              maxValue={maxFeedbackTextLength}
              showErrorMessage={false}
              countDirectionUp={false}
            />
          </Box>
        </Stack>
      )}
    </>
  );
};

export default RatingStep;
