import React, { useMemo, useState, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { MapContainer, TileLayer, Marker } from "react-leaflet";

import DefaultLayout from "./layouts/DefaultLayout";
import { getProfile } from "~/services/users";
import LoadingIndicator from "~/components/LoadingIndicator";
import { formatFriendlyDate } from "~/utils/date";
import { AuthContext } from "~/components/providers/AuthProvider";

const IncidentInfoScreen = () => {
  const { isSeniorPeerWorker } = useContext(AuthContext);
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<UserProfile>();

  useEffect(() => {
    if (!userId) return;

    getProfile(userId)
      .then((res) => setProfile(res))
      .finally(() => setLoading(false));
  }, [userId]);

  const ipLocation = useMemo(
    () =>
      profile?.lastLoginLocation?.location
        ? ([
            profile.lastLoginLocation.location.lat,
            profile.lastLoginLocation.location.lon,
          ] as [number, number])
        : null,
    [profile]
  );

  return (
    <DefaultLayout noHeaderColour>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
      </Helmet>
      {loading && <LoadingIndicator />}
      {/* 
      Maintain the following attribute:
      data-heap-redact-text="true"
      on the outermost element to ensure the target text PII data is not tracked in Heap.
      */}
      {profile && (
        <Box data-heap-redact-text="true">
          <Typography variant="h1" mt={4}>
            {profile.displayName}
          </Typography>
          <Typography variant="caption">{userId}</Typography>
          <Typography>
            Last online:{" "}
            {formatFriendlyDate(parseInt(profile.lastOnline!), {
              todayFormat: "[Today at] h:mma",
              fallbackFormat: "dddd D MMMM [at] h:mma",
            })}
          </Typography>
          <Typography>Latest IP address: {profile.lastLoginIp}</Typography>
          <Typography>
            Latest IP location: {profile.lastLoginLocation?.city}{" "}
            {profile.lastLoginLocation?.postcode}{" "}
            {profile.lastLoginLocation?.state}
          </Typography>
          {isSeniorPeerWorker && (profile.email || profile.phoneNumber) && (
            <Typography>
              User contact info: {profile.email || profile.phoneNumber}
            </Typography>
          )}
          {ipLocation && (
            <Box sx={{ ".leaflet-container": { width: 1, height: 300 } }}>
              <MapContainer
                center={ipLocation}
                zoom={13}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={ipLocation}></Marker>
              </MapContainer>
            </Box>
          )}
        </Box>
      )}
    </DefaultLayout>
  );
};

export default IncidentInfoScreen;
