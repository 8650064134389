import { colours, darkTheme, lightTheme } from "~/theme";

export const sendbirdLightTheme = {
  // light theme
  "--sendbird-light-primary-100": "#4d9ddd",
  "--sendbird-light-primary-200": "#1a81d4",
  "--sendbird-light-primary-300": lightTheme.palette.primary.main,
  "--sendbird-light-primary-400": lightTheme.palette.dark,
  "--sendbird-light-primary-500": "#00457c",
  "--sendbird-light-secondary-100": "#fff69f",
  "--sendbird-light-secondary-200": "#fff37f",
  "--sendbird-light-secondary-300": "#fff05f",
  "--sendbird-light-secondary-400": "#e6d856",
  "--sendbird-light-secondary-500": "#b3a843",
  "--sendbird-light-information-100": lightTheme.palette.primary.main,
  "--sendbird-light-error-100": "#ca555f",
  "--sendbird-light-error-200": "#c23d48",
  "--sendbird-light-error-300": "#b30c1a",
  "--sendbird-light-error-400": "#8f0a15",
  "--sendbird-light-error-500": "#6b0710",
  // "--sendbird-light-background-50": "#FFFFFF",
  // "--sendbird-light-background-100": "#eeeeee",
  // "--sendbird-light-background-200": "#e0e0e0",
  // "--sendbird-light-background-300": "#bdbdbd",
  // "--sendbird-light-background-400": "#393939",
  // "--sendbird-light-background-500": "#2C2C2C",
  // "--sendbird-light-background-600": "#161616",
  // "--sendbird-light-background-700": "#000000",
  // "--sendbird-light-overlay-01": "rgba(0, 0, 0, 0.55)",
  // "--sendbird-light-overlay-02": "rgba(0, 0, 0, 0.32)",
  // "--sendbird-light-onlight-01": "rgba(0, 0, 0, 0.88)",
  "--sendbird-light-onlight-02": lightTheme.palette.text.primary,
  // "--sendbird-light-onlight-03": "rgba(0, 0, 0, 0.38)",
  // "--sendbird-light-onlight-04": "rgba(0, 0, 0, 0.12)",
  "--sendbird-light-ondark-01": lightTheme.palette.text.primary, // Ensures chat text colour is black on light blue background
  // "--sendbird-light-ondark-02": "rgba(255, 255, 255, 0.50)",
  // "--sendbird-light-ondark-03": "rgba(255, 255, 255, 0.38)",
  // "--sendbird-light-ondark-04": "rgba(255, 255, 255, 0.12)",
};

export const sendbirdDarkTheme = {
  // dark theme
  "--sendbird-dark-primary-100": "#B5DFCE",
  "--sendbird-dark-primary-200": "#ADDBC9",
  "--sendbird-dark-primary-300": "#9CC5B5",
  "--sendbird-dark-primary-400": "#C1E4D6",
  "--sendbird-dark-primary-500": "#D6EDE4",
  "--sendbird-dark-secondary-100": "#fff69f",
  "--sendbird-dark-secondary-200": "#fff37f",
  "--sendbird-dark-secondary-300": "#fff05f",
  "--sendbird-dark-secondary-400": "#e6d856",
  "--sendbird-dark-secondary-500": "#b3a843",
  "--sendbird-dark-information-100": darkTheme.palette.primary.main,
  "--sendbird-dark-error-100": "#ca555f",
  "--sendbird-dark-error-200": "#c23d48",
  "--sendbird-dark-error-300": "#b30c1a",
  "--sendbird-dark-error-400": "#8f0a15",
  "--sendbird-dark-error-500": "#6b0710",
  // "--sendbird-dark-background-50": "#FFFFFF",
  // "--sendbird-dark-background-100": "#eeeeee",
  // "--sendbird-dark-background-200": "#e0e0e0",
  // "--sendbird-dark-background-300": "#bdbdbd",
  // "--sendbird-dark-background-400": "#393939",
  // "--sendbird-dark-background-500": "#2C2C2C",
  // "--sendbird-dark-background-600": "#161616",
  // "--sendbird-dark-background-700": "#000000",
  // "--sendbird-dark-overlay-01": "rgba(0, 0, 0, 0.55)",
  // "--sendbird-dark-overlay-02": "rgba(0, 0, 0, 0.32)",
  "--sendbird-dark-onlight-01": darkTheme.palette.primary.contrastText,
  // "--sendbird-dark-onlight-02": "rgba(0, 0, 0, 0.50)",
  // "--sendbird-dark-onlight-03": "rgba(0, 0, 0, 0.38)",
  // "--sendbird-dark-onlight-04": "rgba(0, 0, 0, 0.12)",
  "--sendbird-dark-ondark-01": darkTheme.palette.primary.contrastText, // Ensure text colour is black on white background in dark mode for chat session
  // "--sendbird-dark-ondark-02": "rgba(255, 255, 255, 0.50",
  // "--sendbird-dark-ondark-03": "rgba(255, 255, 255, 0.38)",
  // "--sendbird-dark-ondark-04": "rgba(255, 255, 255, 0.12)",
};

export const sendbirdColours = {
  "& .sendbird-theme--dark": {
    "--sendbird-add-reaction-button-border-hover":
      darkTheme.palette.text.primary,
    "--sendbird-selected-reaction-button-border-hover":
      darkTheme.palette.text.primary,
    "--sendbird-iconbutton-color": darkTheme.palette.primary.main,
    "--sendbird-message-input-border-active": darkTheme.palette.primary.main,
    "& .sendbird-reaction-button": {
      "&:hover, &:focus": {
        borderColor: darkTheme.palette.text.primary,
      },
    },
  },
  "& .sendbird-theme--light": {
    "--sendbird-add-reaction-button-border-hover":
      lightTheme.palette.text.primary,
    "--sendbird-selected-reaction-button-border-hover":
      lightTheme.palette.text.primary,
    "--sendbird-iconbutton-color": lightTheme.palette.primary.main,
    "--sendbird-message-input-border-active": lightTheme.palette.primary.main,

    "& .sendbird-reaction-button": {
      "&:hover, &:focus": {
        borderColor: lightTheme.palette.text.primary,
      },
    },
  },

  // the reactions modal exists outside of the chat container, so need to set these in the global colours
  "& .sendbird-reaction-button--selected": {
    borderColor: colours.plum.main,
    backgroundColor: colours.plum.main,

    "&:hover, &:focus": {
      borderColor: "var(--sendbird-selected-reaction-button-border-hover)",
    },
  },

  ...sendbirdLightTheme,
  ...sendbirdDarkTheme,
};
