import React, { FC, useState } from "react";

import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Alert, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import PasswordField from "~/components/PasswordField";
import LoadingButton from "../LoadingButton";

type Props = {
  user: CognitoUser;
  onSetPassword: () => void;
};

interface LoginFormValues {
  username: string;
  newPassword: string;
}
const PasswordResetForm: FC<Props> = ({ user, onSetPassword }) => {
  const [formError, setFormError] = useState("");

  const methods = useForm<LoginFormValues>();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: LoginFormValues) => {
    setFormError("");
    try {
      await Auth.completeNewPassword(user, data.newPassword, {});
    } catch (ex) {
      console.error(ex);
      setFormError((ex as Error).message);
      return;
    }
    // log in again to get all user attributes
    await Auth.signIn(user.getUsername(), data.newPassword);

    onSetPassword();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography>Please set your new password</Typography>
          <PasswordField
            name="newPassword"
            label="New Password"
            autoComplete="new-password"
          />
          {formError && <Alert severity="error">{formError}</Alert>}

          <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
            <LoadingButton color="primary" type="submit" loading={isSubmitting}>
              Set password
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default PasswordResetForm;
