import React, { FC, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { reassignBooking } from "~/services/booking";

import { Box, Stack, Typography, BoxProps, Alert } from "@mui/material";

import FormField from "~/components/FormField";
import { withPrettyLabels } from "~/utils/validation";
import LoadingButton from "../LoadingButton";
import UserAutocomplete from "./UserAutocomplete";
import axios from "axios";

type reassignValues = {
  bookingId: string;
  peerworkerId: string;
};

const createReassignSchema = withPrettyLabels(
  yup.object({
    bookingId: yup.string().required(),
    peerworkerId: yup.string().required(),
  })
);

type Props = {
  sx?: BoxProps["sx"];
  users: ContactableUser[];
};

const ReassignChatForm: FC<Props> = ({ sx, users }) => {
  const methods = useForm({
    resolver: yupResolver(createReassignSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const [result, setResult] = useState({ success: false, message: "" });

  const onSubmit = async (data: reassignValues) => {
    try {
      setResult({ success: false, message: "" });

      const res = await reassignBooking(data.peerworkerId, data.bookingId);
      if (res)
        setResult({ success: true, message: "Successfully Reassigned Chat" });
    } catch (ex) {
      console.error(ex);

      let message =
        axios.isAxiosError(ex) && ex.response?.data?.errorMessage
          ? ex.response?.data?.errorMessage
          : (ex as Error).message;

      switch (message) {
        case "timekit_error":
          message = "Can't reassign booking due to a Timekit error.";
          break;
      }
      setResult({
        success: false,
        message,
      });
    }
  };

  const peerworkerUsers = users.filter(
    (user) =>
      ["peerworker", "seniorpeerworker"].includes(user.role) &&
      user.isTimekitRegistered
  );

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={sx}
      >
        <Stack
          spacing={2}
          sx={{
            p: 2,
            borderRadius: 2,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h2">Reassign a chat</Typography>
          <FormField name="bookingId" label="Booking id" required />
          <UserAutocomplete
            users={peerworkerUsers}
            name="peerworkerId"
            label="Peer worker id"
            required
          />

          {result.message && (
            <Alert
              severity={result.success ? "success" : "error"}
              sx={{ my: 2 }}
            >
              <Typography>{result.message}</Typography>
            </Alert>
          )}

          <LoadingButton color="primary" type="submit" loading={isSubmitting}>
            Reassign Chat
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default ReassignChatForm;
