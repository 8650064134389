import React, { useCallback } from "react";
import { Box, BoxProps, Theme, ThemeProvider } from "@mui/material";
import { OverrideProps } from "@mui/material/OverridableComponent";

// all these props are just so we can use `<ThemeBox component={Something} somethingProp="test">`

type ThemeBoxTypeMap = {
  props: {
    themes: [ThemeLike] | [ThemeLike, ThemeLike];
  };
  defaultComponent: React.ElementType;
};
type ThemeBoxType = <C extends React.ElementType = React.ElementType<BoxProps>>(
  props: {
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component?: C;
  } & OverrideProps<ThemeBoxTypeMap, C>
) => JSX.Element;

export type ThemeLike = Theme | ((outer: Theme) => Theme);

// re-export ThemeBoxProps but make thenes an optional override
export type ThemeBoxProps = Omit<Parameters<ThemeBoxType>[0], "themes"> & {
  themes?: [ThemeLike] | [ThemeLike, ThemeLike];
};

const ThemeBox: ThemeBoxType = ({ children, themes, ...props }) => {
  const themeBuilder = useCallback(
    (parent: Theme) => {
      let theme: ThemeLike;

      // if single theme, just use it. otherwise treat them as a light/dark pair
      if (themes.length === 1) {
        theme = themes[0];
      } else if (parent.palette.mode === "dark") {
        theme = themes[1];
      } else {
        theme = themes[0];
      }

      if (typeof theme === "function") return theme(parent);
      return theme;
    },
    [themes]
  );

  return (
    <ThemeProvider theme={themeBuilder}>
      <Box
        {...props}
        sx={{
          color: "text.primary",
          ...props.sx,
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default ThemeBox;
