import React, { FC } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { colours } from "~/theme";

type Props = {
  hasStarted: boolean; // probably don't need anymore
  onJoin: () => void; // probably don't need anymore
  onSelect: (choice: "breathe" | "sounds") => void;
  hasCheckedIn?: boolean;
};

const ExerciseSelection: FC<Props> = ({ onSelect, hasCheckedIn }) => {
  return (
    <Box
      sx={{
        color: colours.white.main,
        backgroundColor: colours.navy.main,
        borderRadius: "24px",
      }}
    >
      <Stack spacing={2} alignItems="center" px={2} py={4}>
        {hasCheckedIn && (
          <Typography variant="h4" align="center">
            Thanks for letting them know how you're feeling.
          </Typography>
        )}
        <Typography align="center">
          Some people find that a calming exercise helps to put them in a better
          headspace. If you want, you can try a calming exercise while you wait.
        </Typography>

        <Button
          fullWidth
          id="exercise-breathe"
          color="primary"
          onClick={() => onSelect("breathe")}
        >
          Try a breathing exercise
        </Button>
        <Button
          fullWidth
          id="exercise-nature-sounds"
          color="primary"
          onClick={() => onSelect("sounds")}
        >
          Listen to nature sounds
        </Button>
      </Stack>
    </Box>
  );
};

export default ExerciseSelection;
