import { Avatar, Box, Typography, TypographyProps } from "@mui/material";
import React, { FC } from "react";

type Props = UserProfile & {
  nameColour?: TypographyProps["color"];
  roleName?: string;
};
const Profile: FC<Props> = ({
  displayName,
  profile,
  avatarUrl,
  nameColour,
  roleName,
}) => {
  return (
    <Box
      className="user-profile"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Avatar src={avatarUrl} sx={{ width: 64, height: 64 }} />
      <Box
        sx={{
          ml: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          component="p"
          fontWeight="bold"
          color={nameColour}
        >
          {displayName}
        </Typography>
        <Typography>{profile}</Typography>
        {roleName && <Typography fontWeight="bold">{roleName}</Typography>}
      </Box>
    </Box>
  );
};

export default Profile;
