// pre checkin images
import preCIDesktop1 from "~/assets/preCIDesktop1.svg";
import preCIDesktop2 from "~/assets/preCIDesktop2.svg";
import preCIDesktop3 from "~/assets/preCIDesktop3.svg";
import preCIDesktop4 from "~/assets/preCIDesktop4.svg";
import preCIDesktop5 from "~/assets/preCIDesktop5.svg";

import preCIMobile1 from "~/assets/preCIMobile1.svg";
import preCIMobile2 from "~/assets/preCIMobile2.svg";
import preCIMobile3 from "~/assets/preCIMobile3.svg";
import preCIMobile4 from "~/assets/preCIMobile4.svg";
import preCIMobile5 from "~/assets/preCIMobile5.svg";

// post checkin images
import postCIDesktop1 from "~/assets/postCIDesktop1.svg";
import postCIDesktop2 from "~/assets/postCIDesktop2.svg";
import postCIDesktop3 from "~/assets/postCIDesktop3.svg";
import postCIDesktop4 from "~/assets/postCIDesktop4.svg";
import postCIDesktop5 from "~/assets/postCIDesktop5.svg";

import postCIMobile1 from "~/assets/postCIMobile1.svg";
import postCIMobile2 from "~/assets/postCIMobile2.svg";
import postCIMobile3 from "~/assets/postCIMobile3.svg";
import postCIMobile4 from "~/assets/postCIMobile4.svg";
import postCIMobile5 from "~/assets/postCIMobile5.svg";

export const preSliderValues = [
  {
    value: 1,
    label: "I'm okay",
  },
  {
    value: 2,
    label: "It's making me feel uneasy",
  },
  {
    value: 3,
    label: "It's a lot to handle",
  },
  {
    value: 4,
    label: "It's very concerning",
  },
  {
    value: 5,
    label: "It's a huge deal",
  },
];

export const postSliderValues = [
  {
    value: 1,
    label: "Much worse than before",
  },
  {
    value: 2,
    label: "A bit worse than before",
  },
  {
    value: 3,
    label: "About the same",
  },
  {
    value: 4,
    label: "A bit better than before",
  },
  {
    value: 5,
    label: "Much better than before",
  },
];

export const preCheckInValues = {
  "Skipped check-in": {
    iconSrc: preCIDesktop3,
    mobileIconSrc: preCIMobile3,
  },
  "1/5 - I'm okay": {
    iconSrc: preCIDesktop1,
    mobileIconSrc: preCIMobile1,
  },
  "2/5 - It's making me feel uneasy": {
    iconSrc: preCIDesktop2,
    mobileIconSrc: preCIMobile2,
  },
  "3/5 - It's a lot to handle": {
    iconSrc: preCIDesktop3,
    mobileIconSrc: preCIMobile3,
  },
  "4/5 - It's very concerning": {
    iconSrc: preCIDesktop4,
    mobileIconSrc: preCIMobile4,
  },
  "5/5 - It's a huge deal": {
    iconSrc: preCIDesktop5,
    mobileIconSrc: preCIMobile5,
  },
} as const;

export const postCheckInValues = {
  "Skipped check-in": {
    iconSrc: postCIDesktop3,
    mobileIconSrc: postCIMobile3,
  },
  "1/5 - Much worse than before": {
    iconSrc: postCIDesktop1,
    mobileIconSrc: postCIMobile5,
  },
  "2/5 - A bit worse than before": {
    iconSrc: postCIDesktop2,
    mobileIconSrc: postCIMobile4,
  },
  "3/5 - About the same": {
    iconSrc: postCIDesktop3,
    mobileIconSrc: postCIMobile3,
  },
  "4/5 - A bit better than before": {
    iconSrc: postCIDesktop4,
    mobileIconSrc: postCIMobile2,
  },
  "5/5 - Much better than before": {
    iconSrc: postCIDesktop5,
    mobileIconSrc: postCIMobile1,
  },
} as const;

export type PreCheckInNames = keyof typeof preCheckInValues;
export type PostCheckInNames = keyof typeof postCheckInValues;
