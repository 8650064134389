import dayjs from "dayjs";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";

import { getSecureAxiosClient } from "~/utils/auth";

const client = getSecureAxiosClient(process.env.REACT_APP_API_BASE!);

const formatDate = (booking: any) => dayjs(booking.start).format("YYYY-MM-DD");
const formatTime = (booking: any) => dayjs(booking.start).format("h:mm a");

export const listAvailableTimes = async () => {
  const { data } = await client.get("/availability");

  return mapValues(groupBy(data, formatDate), (x) =>
    x.map((y) => ({
      ...y,
      day: formatDate(y),
      time: formatTime(y),
    }))
  );
};

export const getMyBookings = async (): Promise<Booking[]> => {
  const { data } = await client.get("/bookings");
  return data;
};

export const cancelBooking = async (bookingId: string): Promise<void> => {
  await client.delete(`/bookings/${bookingId}`);
};

export const reassignBooking = async (peerworkerId: string, bookingId: string): Promise<string> => {
  const { data } = await client.put(`/bookings/${bookingId}/reassign`, peerworkerId);
  return data;
};
