import React, { FC } from "react";
import { Box, Typography } from "@mui/material";

type ActionMessageProps = {
  children: React.ReactNode;
};

const ActionMessage: FC<ActionMessageProps> = ({ children }) => {
  return (
    <Box
      sx={{
        mx: [2, 3],
        py: 2,
        pr: 2,
        pl: [6, 2],
        border: 0,
        borderTop: 1,
        borderBottom: 1,
        borderStyle: "dashed",
        borderColor: "grey.500",
        mb: 2,
        textAlign: "center",
        position: "relative",
      }}
    >
      {typeof children === "string" ? (
        <Typography>{children}</Typography>
      ) : (
        children
      )}
    </Box>
  );
};

export default ActionMessage;
