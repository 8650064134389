import React, { useCallback, useContext, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import {
  Alert,
  MenuItem,
  Stack,
  Button,
  AlertColor,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import DefaultLayout from "./layouts/DefaultLayout";

import LoadingButton from "~/components/LoadingButton";
import { AuthContext } from "~/components/providers/AuthProvider";
import { randomUsername } from "~/components/auth/SignupForm";
import FormField from "~/components/FormField";
import ChangePassword from "~/components/auth/ChangePassword";
import { normaliseUsername } from "~/components/auth/utils";

import { getCurrentTimezone, timezoneOptions } from "~/utils/date";
import { getSecureAxiosClient } from "~/utils/auth";
import {
  withPrettyLabels,
  displayNameSchema,
  emailOrPhoneSameTypeOnlySchema,
} from "~/utils/validation";
import FormSelect from "~/components/FormSelect";

const client = getSecureAxiosClient(process.env.REACT_APP_API_BASE!);
interface UserDetails {
  displayName: string;
  emailAddressOrMobileNumber: string;
  timezone: string;
  pronouns?: string;
  bio?: string;
  avatarUrl?: string;
  age?: string;
}
const myDetailsSchema = withPrettyLabels(
  yup.object({
    displayName: displayNameSchema,
    emailAddressOrMobileNumber: emailOrPhoneSameTypeOnlySchema,
    timezone: yup
      .string()
      .required()
      .oneOf(
        timezoneOptions.map(
          (t) => t.value,
          "Must be a valid Australian timezone"
        )
      ),
    pronouns: yup
      .string()
      .when("$role", ([role], schema) =>
        (role === "peerworker" ? schema.required() : schema).label("Pronouns")
      ),
    bio: yup
      .string()
      .max(40)
      .when("$role", ([role], schema) =>
        (role === "peerworker" ? schema.required() : schema).label("Short bio")
      ),
    avatarUrl: yup
      .string()
      .url()
      .when("$role", ([role], schema) =>
        (role === "peerworker" ? schema.required() : schema).label(
          "Profile picture"
        )
      ),
  })
);
const MyDetailsScreen = () => {
  // const navigate = useNavigate();
  const { authUser, setAuthUser, isStaffMember, isPeerWorker } =
    useContext(AuthContext);
  const [statusMessage, setStatusMessage] = useState<{
    msg: string;
    severity: AlertColor;
  }>({ msg: "", severity: "info" });

  const accountTypeEmail = Boolean(authUser.attributes?.["email"]);
  const initialTimezone =
    authUser.attributes?.["zoneinfo"] || getCurrentTimezone();

  const methods = useForm({
    context: { accountTypeEmail, role: authUser.attributes?.["custom:role"] },
    resolver: yupResolver(myDetailsSchema),
    defaultValues: {
      displayName: authUser.attributes?.["preferred_username"],
      emailAddressOrMobileNumber:
        authUser.attributes?.["email"] || authUser.attributes?.["phone_number"],
      timezone: initialTimezone,
      pronouns: authUser.attributes?.["custom:pronouns"],
      bio: authUser.attributes?.["profile"],
      avatarUrl: authUser.attributes?.["picture"],
    },
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;
  const displayName = watch("displayName");
  const avatarUrl = watch("avatarUrl");

  const handleUpdate = async (data: UserDetails) => {
    const emailOrPhone = normaliseUsername(data.emailAddressOrMobileNumber);

    try {
      await client.put("/profile", {
        displayName: data.displayName,
        email: emailOrPhone.startsWith("+") ? "" : emailOrPhone,
        mobileNumber: emailOrPhone.startsWith("+") ? emailOrPhone : "",
        timezone: data.timezone,
        pronouns: data.pronouns,
        bio: data.bio,
        avatarUrl: data.avatarUrl,
      });
      setAuthUser(await Auth.currentAuthenticatedUser({ bypassCache: true }));

      setStatusMessage({
        msg: "Details updated successfully!",
        severity: "success",
      });
    } catch (ex) {
      console.error(ex);
      setStatusMessage({
        msg: (ex as Error).message || "Failed to updated details",
        severity: "error",
      });
    }
  };

  const handleGenerateName = useCallback(() => {
    setValue("displayName", randomUsername(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [setValue]);

  const handlePickTimezone = useCallback(() => {
    setValue("timezone", getCurrentTimezone(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [setValue]);

  return (
    <DefaultLayout title="Your details" noHeaderColour sx={{ pt: 2 }}>
      <FormProvider {...methods}>
        <Stack
          component="form"
          spacing={2}
          onSubmit={handleSubmit(handleUpdate)}
          noValidate
        >
          <FormField
            name="displayName"
            label="Display name"
            required
            // Not sure why this needs to be manually controlled
            // but when generating the name it doesn't correctly update
            InputLabelProps={{ shrink: Boolean(displayName) }}
            InputProps={{
              endAdornment: (
                <Button
                  variant="outlined"
                  color="inherit"
                  title="Generate a display name"
                  onClick={handleGenerateName}
                  sx={{ fontSize: "0.875rem", whiteSpace: "nowrap", px: 3 }}
                >
                  Go random
                </Button>
              ),
            }}
          />

          <FormField
            name="emailAddressOrMobileNumber"
            label={accountTypeEmail ? "Email address" : "Mobile number"}
            required
          />

          <FormSelect
            name="timezone"
            label="Timezone (for reminders)"
            required
            defaultValue={initialTimezone}
            InputProps={{
              endAdornment: (
                <Button
                  variant="outlined"
                  color="inherit"
                  title="Use my current timezone"
                  onClick={handlePickTimezone}
                  sx={{ fontSize: "0.875rem", whiteSpace: "nowrap", px: 3 }}
                >
                  Use current
                </Button>
              ),
            }}
          >
            {timezoneOptions.map((tz) => (
              <MenuItem key={tz.value} value={tz.value}>
                {tz.label}
              </MenuItem>
            ))}
          </FormSelect>

          {isStaffMember && (
            <>
              <FormField
                name="pronouns"
                label="Pronouns"
                required={isPeerWorker}
              />
              <FormField name="bio" label="Short bio" required={isPeerWorker} />
              <FormField
                name="avatarUrl"
                label="Profile picture"
                required={isPeerWorker}
                helperText="Aim for mostly square and under 1mb file size"
              />
              {avatarUrl && (
                <>
                  <Typography variant="caption">
                    How this picture will appear to helpseekers on site:
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <Avatar src={avatarUrl} sx={{ width: 64, height: 64 }} />
                      <Typography variant="caption">During checkin</Typography>
                    </Box>
                    <Box>
                      <Avatar src={avatarUrl} sx={{ width: 28, height: 28 }} />
                      <Typography variant="caption">In chat</Typography>
                    </Box>
                  </Stack>
                </>
              )}
            </>
          )}

          {statusMessage.msg && (
            <Alert severity={statusMessage.severity}>{statusMessage.msg}</Alert>
          )}

          <LoadingButton
            color="primary"
            id="update-details"
            loading={isSubmitting}
            sx={{ alignSelf: ["stretch", "flex-end"] }}
            type="submit"
          >
            Update your details
          </LoadingButton>
        </Stack>
      </FormProvider>

      <ChangePassword />
    </DefaultLayout>
  );
};

export default MyDetailsScreen;
