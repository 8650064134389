import MessageInputWrapper from "@sendbird/uikit-react/GroupChannel/components/MessageInputWrapper";
import { useGroupChannelContext } from "@sendbird/uikit-react/GroupChannel/context";
import React, { useEffect } from "react";
import { atLatestMessage } from "~/utils/chat";

type CustomMessageInputProps = {
  messageSending: boolean;
};

export const CustomMessageInput = ({
  messageSending,
}: CustomMessageInputProps) => {
  const context = useGroupChannelContext();

  useEffect(() => {
    const { scrollRef } = context;

    const toScrollElement =
      scrollRef?.current ||
      document.querySelector(".sendbird-conversation__messages-padding");

    // Prevents scrolling when a user sends a message. See below for the specific line I am attempting to prevent from running:
    // https://github.com/sendbird/sendbird-uikit-react/blob/078c0971dfb7a541aae3abc98a19f3f37ffaa387/src/modules/GroupChannel/context/hooks/useMessageListScroll.tsx#L55
    // The internal Sendbird logic may change, so be *extremely* weary of this code
    if (toScrollElement) {
      toScrollElement.scroll = (options, y?: number) => {
        const isScrolledToBottom = atLatestMessage();

        if (!messageSending || isScrolledToBottom) {
          if (options && typeof options === "number" && y) {
            toScrollElement.scrollTo(options, y);
          } else if (typeof options === "object") {
            toScrollElement.scrollTo(options);
          }
        }
      };
    }
  }, [context, messageSending]);

  return <MessageInputWrapper />;
};
