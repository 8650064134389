import React, { FC, useContext } from "react";
import { Stack, IconButton, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import AuthButton from "~/components/auth/AuthButton";
import { AuthContext } from "./providers/AuthProvider";
import NavButton from "./NavButton";
import ThemeToggle from "./ThemeToggle";
import { colours } from "~/theme";

type Props = {
  onClose: () => void;
};

const MenuOptions: FC<Props> = ({ onClose }) => {
  const { isLoggedIn, isAdmin, isSeniorPeerWorker } = useContext(AuthContext);

  return (
    <Stack
      spacing={2}
      p={[2, 3]}
      pt={[1, "12px"]}
      height="100%"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? colours.grey.main
            : colours.white.main,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="180px"
      >
        <ThemeToggle />
        <IconButton
          autoFocus
          aria-label="Close menu"
          onClick={onClose}
          sx={{ color: "inherit" }}
        >
          <Close />
        </IconButton>
      </Stack>

      {isLoggedIn && (
        <NavButton to="/my-details" variant="outlined">
          Your details
        </NavButton>
      )}
      {(isAdmin || isSeniorPeerWorker) && (
        <NavButton to="/admin" variant="outlined">
          Admin
        </NavButton>
      )}
      <AuthButton />

      <Button
        color="urgent"
        component="a"
        href="https://au.reachout.com/urgent-help"
        target="_blank"
        sx={{
          "&&": { mt: "auto" },
        }}
      >
        Urgent help
      </Button>
    </Stack>
  );
};

export default MenuOptions;
