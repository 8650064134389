import React, { FC, useCallback, useEffect, useState } from "react";
import { Alert, Container, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import LoadingButton from "~/components/LoadingButton";
import LoadingIndicator from "~/components/LoadingIndicator";
import DayAvailability from "~/components/booking/DayAvailability";
import { listAvailableTimes } from "~/services/booking";
import { getSecureAxiosClient } from "~/utils/auth";
import gaCreate from "~/utils/gaCreate";
import { useNavigate } from "react-router-dom";
import ThemeBox from "~/components/containers/ThemeBox";
import { lightTheme } from "~/theme";
import { Helmet } from "react-helmet-async";
import BrandedBox from "~/components/containers/BrandedBox";
import { BreakMessage } from "~/components/BreakMessage";
import { ApiErrorCode } from "~/utils/errors";
import { AxiosResponse } from "axios";
const client = getSecureAxiosClient(process.env.REACT_APP_API_BASE!);

const defaultTimeError =
  "Sorry, the time you selected is no longer available. Choose another time.";
const defaultError =
  "Unable to book session. Please select another time or try again later.";

const BookingAvailability: FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [times, setTimes] = useState<
    Record<string, SessionTime[]> | undefined
  >();
  const [selectedSession, setSelectedSession] = useState<SessionTime>();
  const [removedAvailability, setRemovedAvailability] = useState<SessionTime[]>(
    []
  );
  const setAvailableTimes = useCallback(async () => {
    const grouped = await listAvailableTimes();

    setTimes(grouped);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (times) return;
    setAvailableTimes();
  }, [setAvailableTimes, times]);

  const handleBooking = async () => {
    if (!selectedSession) return;

    try {
      setError("");
      setSubmitting(true);
      const { data: booking } = await client.post("/bookings", {
        id: selectedSession.id,
        start: selectedSession.start,
        end: selectedSession.end,
      });
      if (window.heap) {
        window.heap.track("session-booked", {
          dayOfWeek: dayjs(selectedSession.start).format("ddd"),
          hour: dayjs(selectedSession.start).hour(),
        });
      }
      window.dataLayer?.push({ event: "peerchat_booking" });
      gaCreate();
      window.ga?.("send", "event", "PeerChat", "Click", "Step 3 - Book a chat");

      navigate("/booking/success", { state: booking });
    } catch (ex: any) {
      handleBookingError(selectedSession, ex.response);
      console.error(ex);
    }
  };

  const updateUnavailable = useCallback(
    (selectedSession: SessionTime, errorMessage?: string) => {
      setError(errorMessage || defaultTimeError);
      setRemovedAvailability((prevRemoved) => [
        ...prevRemoved,
        selectedSession,
      ]);
      setSelectedSession(undefined);
    },
    []
  );

  const handleBookingError = useCallback(
    (selectedSession: SessionTime, response: AxiosResponse) => {
      const errorCode = response?.data?.errorMessage as ApiErrorCode;
      switch (errorCode) {
        case "double_booking":
          updateUnavailable(selectedSession);
          break;
        case "invalid_start_date":
          updateUnavailable(selectedSession);
          break;
        default:
          updateUnavailable(selectedSession, defaultError);
      }

      setSubmitting(false);
    },
    [updateUnavailable]
  );

  return (
    <>
      <Helmet>
        <title>Find a session</title>
        <meta
          name="description"
          content="Find a time that works for you! Chat with a peer worker who gets it, on ReachOut PeerChat."
        />
      </Helmet>
      <BrandedBox themeType="navy">
        <Container
          maxWidth="md"
          sx={{
            py: 4,
            px: [5, 0],
            minHeight: "80vh",
          }}
        >
          <Stack spacing={2}>
            <BreakMessage />
            <Typography variant="h1">Book a chat</Typography>

            {loading && <LoadingIndicator />}

            <ThemeBox themes={[lightTheme]} component={Stack} spacing={1}>
              {times &&
                Object.entries(times).map(([day, sessions], i) => (
                  <DayAvailability
                    key={day}
                    date={day}
                    sessionTimes={sessions}
                    selectedSession={selectedSession}
                    onSelect={setSelectedSession}
                    startOpen={i === 0}
                    removedAvailability={removedAvailability}
                  />
                ))}
            </ThemeBox>

            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}

            <LoadingButton
              onClick={handleBooking}
              color="primary"
              disabled={!selectedSession || submitting}
              loading={submitting}
              sx={{
                alignSelf: ["stretch", "flex-start"],
                minWidth: 200,
                "&&": { mt: 4 },
              }}
            >
              Book a chat
            </LoadingButton>
            <div className="visually-hidden">
              <div aria-live="polite">
                {selectedSession
                  ? "Please select the book a chat button to proceed"
                  : "Please select a booking time"}
              </div>
            </div>
          </Stack>
        </Container>
      </BrandedBox>
    </>
  );
};

export default BookingAvailability;
