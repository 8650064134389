import React, { FC } from "react";
import { Box, BoxProps, IconButton, Portal } from "@mui/material";
import { colours } from "~/theme";
import { CloseRounded } from "@mui/icons-material";

type Props = {
  message: string | React.ReactElement;
  onClose?: React.MouseEventHandler;
  sx?: BoxProps["sx"];
};

const ChatActions: FC<Props> = ({ message, onClose, sx = [] }) => {
  return (
    <Portal
      container={() => document.querySelector(".sendbird-conversation__footer")}
    >
      <Box
        role="alert"
        sx={[
          {
            p: 2,
            pt: { xs: 4, sm: 2 },
            position: "absolute",
            bottom: "85px",
            right: "16px",
            left: "16px",
            backgroundColor: (theme) =>
              theme.palette.mode === "dark"
                ? colours.grey.main
                : colours.white.main,
            color: "text.primary",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark"
                ? colours.white.main
                : colours.navy.main,
            borderRadius: { xs: "16px 16px 16px 40px", md: "15px" },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <IconButton
          onClick={onClose}
          aria-label="Close info message"
          sx={{
            position: "absolute",
            top: { xs: "3px", sm: "16px" },
            right: { xs: "3px", sm: "16px" },
          }}
        >
          <CloseRounded fontSize="inherit" />
        </IconButton>
        {message}
      </Box>
    </Portal>
  );
};

export default ChatActions;
