import React, { FC } from "react";
import { Lock, LockOpen } from "@mui/icons-material";
import { IconButton } from "@mui/material";

type Props = {
  isLocked: boolean;
  onClick: () => void;
};

const LockChannelButton: FC<Props> = ({ isLocked, onClick }) => {
  const Icon = isLocked ? LockOpen : Lock;
  return (
    <IconButton
      id="end-chat"
      title={`${isLocked ? "Unlock" : "Lock"} this channel`}
      onClick={onClick}
      sx={{ py: 0 }}
    >
      <Icon sx={{ color: "common.white" }} />
    </IconButton>
  );
};

export default LockChannelButton;
